import React, { useState, useEffect, useRef } from 'react';
import MediaHandler from '../../utils/mediaHandler';

const MediaDisplay = ({ file, type = 'question' }) => {
  const [mediaType, setMediaType] = useState(null);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const mediaRef = useRef(null);
  
  useEffect(() => {
    if (file) {
      setMediaType(MediaHandler.getFileType(file));
      setError(false);
      setRetryCount(0);
    }
  }, [file]);
  
  const handleError = () => {
    if (retryCount < 3) {
      setRetryCount(prev => prev + 1);
      // Try to reload with delay
      setTimeout(() => {
        if (mediaRef.current) {
          mediaRef.current.load();
        }
      }, 1000);
    } else {
      setError(true);
    }
  };
  
  const handleRetry = () => {
    setError(false);
    setRetryCount(0);
    if (mediaRef.current) {
      mediaRef.current.load();
    }
  };
  
  const showFullscreen = (element) => {
    const overlay = document.createElement('div');
    overlay.className = 'fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center';
    overlay.id = 'fullscreen-overlay';
    
    const closeButton = document.createElement('button');
    closeButton.className = 'absolute top-4 right-4 text-white text-3xl p-2';
    closeButton.innerHTML = '&times;';
    closeButton.onclick = () => document.body.removeChild(overlay);
    
    const container = document.createElement('div');
    container.className = 'max-w-full max-h-full';
    
    // Clone the element
    const clone = element.cloneNode(true);
    clone.className = 'max-h-90vh max-w-90vw object-contain';
    
    container.appendChild(clone);
    overlay.appendChild(closeButton);
    overlay.appendChild(container);
    
    // Handle escape key
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        document.body.removeChild(overlay);
        document.removeEventListener('keydown', handleEscape);
      }
    };
    document.addEventListener('keydown', handleEscape);
    
    // Add to body
    document.body.appendChild(overlay);
  };
  
  if (!file) return null;
  
  if (error) {
    return (
      <div className="error-message bg-red-100 text-red-600 p-4 rounded">
        <p>Failed to load media</p>
        <button 
          onClick={handleRetry} 
          className="mt-2 bg-red-600 text-white px-3 py-1 rounded"
        >
          Retry
        </button>
      </div>
    );
  }
  
  const containerId = `media-${type}-${Date.now()}`;
  const commonClasses = 'max-w-full max-h-[50vh] cursor-pointer object-contain';
  
  switch (mediaType) {
    case 'video':
      return (
        <div className="media-container relative w-full" id={containerId}>
          <video 
            ref={mediaRef}
            src={file} 
            className={commonClasses}
            controls={true}
            controlsList="nodownload"
            playsInline
            preload="auto"
            onClick={(e) => showFullscreen(e.target)}
            data-original-src={file}
            onError={handleError}
          >
            <source src={file} type="video/mp4" />
          </video>
        </div>
      );
    
    case 'audio':
      return (
        <div className="media-container" id={containerId}>
          <audio 
            ref={mediaRef}
            src={file}
            controls={true}
            controlsList="nodownload"
            preload="auto"
            data-original-src={file}
            onError={handleError}
          >
            <source src={file} type="audio/mpeg" />
          </audio>
        </div>
      );
    
    case 'image':
      return (
        <div className="media-container relative w-full" id={containerId}>
          <img 
            ref={mediaRef}
            src={file} 
            alt={type} 
            className={commonClasses} 
            onClick={(e) => showFullscreen(e.target)}
            data-original-src={file}
            onError={handleError}
          />
        </div>
      );
    
    default:
      return null;
  }
};

export default MediaDisplay;
import React, { createContext, useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import apiService from '../api/apiService';

// Create the auth context
export const AuthContext = createContext();

// Auth provider component
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [verificationData, setVerificationData] = useState({
    showModal: false,
    phone: '',
    userId: null,
    resendCountdown: 0
  });

  // Check authentication status on component mount
  useEffect(() => {
    checkAuth();
  }, []);

  // Check auth status
  const checkAuth = async () => {
    try {
      setLoading(true);
      const response = await apiService.getProfile();
      
      if (response.data.success) {
        setIsLoggedIn(true);
        setUserData(response.data.data);
        
        // Check if phone verification is needed
        if (!response.data.data.phone_verified) {
          setVerificationData({
            showModal: true,
            phone: response.data.data.phone,
            userId: response.data.data.id,
            resendCountdown: 0
          });
          startResendCountdown();
        }
      } else {
        setIsLoggedIn(false);
        setUserData({});
      }
    } catch (error) {
      setIsLoggedIn(false);
      setUserData({});
      // Don't show error for 401 as it's expected for non-logged in users
      if (error.response?.status !== 401) {
        console.error('Auth check error:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  // Login function
  const login = async (credentials) => {
    try {
      setLoading(true);
      const response = await apiService.login(credentials);
      
      if (response.data.success) {
        if (response.data.data.requires_verification) {
          // Setup verification
          setVerificationData({
            showModal: true,
            phone: response.data.data.phone,
            userId: response.data.data.user_id,
            resendCountdown: 0
          });
          startResendCountdown();
          return { needsVerification: true };
        } else {
          // Complete login
          loginSuccess(response.data.data);
          return { success: true };
        }
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Login failed. Please try again.';
      toast.error(errorMessage);
      return { success: false, error: errorMessage };
    } finally {
      setLoading(false);
    }
  };

  // Register function
  const register = async (userData) => {
    try {
      setLoading(true);
      const response = await apiService.register(userData);
      
      if (response.data.success) {
        // Setup verification
        setVerificationData({
          showModal: true,
          phone: response.data.data.phone,
          userId: response.data.data.user_id,
          resendCountdown: 0
        });
        startResendCountdown();
        toast.success('Account created successfully. Please verify your phone.');
        return { success: true, needsVerification: true };
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Registration failed. Please try again.';
      toast.error(errorMessage);
      return { success: false, error: errorMessage };
    } finally {
      setLoading(false);
    }
  };

  // Verify phone function
  const verifyPhone = async (code) => {
    try {
      setLoading(true);
      const response = await apiService.verifyPhone({
        user_id: verificationData.userId,
        code: code
      });
      
      if (response.data.success) {
        loginSuccess(response.data.data);
        setVerificationData({
          ...verificationData,
          showModal: false
        });
        return { success: true };
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Verification failed. Please try again.';
      toast.error(errorMessage);
      return { success: false, error: errorMessage };
    } finally {
      setLoading(false);
    }
  };

  // Resend verification code
  const resendVerificationCode = async () => {
    if (verificationData.resendCountdown > 0) return { success: false };
    
    try {
      const response = await apiService.resendVerification({
        user_id: verificationData.userId,
        type: 'verification'
      });
      
      if (response.data.success) {
        startResendCountdown();
        toast.success('New code sent');
        return { success: true };
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Failed to send code';
      toast.error(errorMessage);
      return { success: false, error: errorMessage };
    }
  };

  // Logout function
  const logout = async () => {
    try {
      await apiService.logout();
      setIsLoggedIn(false);
      setUserData({});
      toast.success('Logged out successfully');
      return { success: true };
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Logout failed. Please try again.');
      return { success: false };
    }
  };

  // Helper functions
  const loginSuccess = (data) => {
    setIsLoggedIn(true);
    setUserData(data);
    toast.success('Login successful');
  };

  const startResendCountdown = () => {
    setVerificationData(prev => ({
      ...prev,
      resendCountdown: 120 // 2 minutes
    }));
    
    const timer = setInterval(() => {
      setVerificationData(prev => {
        if (prev.resendCountdown <= 1) {
          clearInterval(timer);
          return { ...prev, resendCountdown: 0 };
        }
        return { ...prev, resendCountdown: prev.resendCountdown - 1 };
      });
    }, 1000);
  };

  // Update user data (e.g., after purchasing a package)
  const updateUserData = (newData) => {
    setUserData(prev => ({
      ...prev,
      ...newData
    }));
  };

  // Context value
  const value = {
    isLoggedIn,
    userData,
    loading,
    verificationData,
    checkAuth,
    login,
    register,
    logout,
    verifyPhone,
    resendVerificationCode,
    updateUserData,
    setVerificationData
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the auth context
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useGame } from '../contexts/GameContext';
import { toast } from 'react-toastify';
import GameHeader from '../components/game/GameHeader';
import CategoryGrid from '../components/game/CategoryGrid';
import QuestionDisplay from '../components/game/QuestionDisplay';
import AnswerDisplay from '../components/game/AnswerDisplay';
import TeamSelection from '../components/game/TeamSelection';
import TeamFooter from '../components/game/TeamFooter';
import Loader from '../components/common/Loader';
import Modal from '../components/common/Modal';

const GamePage = () => {
  const { gameId } = useParams();
  const { isLoggedIn } = useAuth();
  const { 
    initGame, 
    loading, 
    currentQuestion, 
    showingAnswer, 
    chooseTeam,
    endGame
  } = useGame();
  
  const navigate = useNavigate();
  const [isInitializing, setIsInitializing] = useState(true);
  const [showGameEndModal, setShowGameEndModal] = useState(false);
  const [gameResults, setGameResults] = useState(null);
  
  // Initialize game on component mount
  useEffect(() => {
    const loadGame = async () => {
      // Redirect if not logged in
      if (!isLoggedIn) {
        navigate('/');
        return;
      }
      
      try {
        setIsInitializing(true);
        const success = await initGame(gameId);
        
        if (!success) {
          toast.error('فشل في تحميل بيانات اللعبة');
          navigate('/');
        }
      } catch (error) {
        console.error('Error initializing game:', error);
        toast.error('حدث خطأ أثناء تحميل اللعبة');
        navigate('/');
      } finally {
        setIsInitializing(false);
      }
    };
    
    loadGame();
  }, [gameId, isLoggedIn, initGame, navigate]);
  
  // Handle end game
  const handleEndGame = async () => {
    const results = await endGame();
    if (results) {
      setGameResults(results);
      setShowGameEndModal(true);
    }
  };
  
  // Go back to home
  const goToHome = () => {
    navigate('/');
  };
  
  if (isInitializing || loading) {
    return <Loader text="جاري تحميل اللعبة..." />;
  }
  
  return (
    <div className="bg-blue-50 flex flex-col h-screen">
      {/* Header */}
      <GameHeader />
      
      {/* Main Content */}
      <main className="flex-grow flex items-center justify-center">
        <div className="container mx-auto py-1 h-full flex flex-col justify-center">
          {/* Categories Grid */}
          {!currentQuestion && <CategoryGrid />}
          
          {/* Question Display */}
          {currentQuestion && !showingAnswer && !chooseTeam && <QuestionDisplay />}
          
          {/* Answer Display */}
          {showingAnswer && <AnswerDisplay />}
          
          {/* Team Selection */}
          {chooseTeam && <TeamSelection />}
        </div>
      </main>
      
      {/* Footer */}
      <TeamFooter />
      
      {/* Game End Modal */}
      <Modal
        isOpen={showGameEndModal}
        onClose={() => setShowGameEndModal(false)}
        title="نهاية اللعبة"
      >
        {gameResults && (
          <div className="text-center">
            <h3 className="text-2xl font-bold mb-4">نتائج اللعبة</h3>
            <div className="winner mb-6">
              <p className="text-xl">
                الفائز: {
                  gameResults.results.reduce((prev, current) => 
                    (prev.score > current.score) ? prev : current
                  ).name
                }
              </p>
              <p className="text-3xl font-bold text-green-600">
                {
                  gameResults.results.reduce((prev, current) => 
                    (prev.score > current.score) ? prev : current
                  ).score
                } نقطة
              </p>
            </div>
            <div className="stats grid grid-cols-2 gap-4">
              {gameResults.results.map(team => (
                <div key={team.id} className="team-stats bg-gray-100 p-4 rounded">
                  <h4 className="font-bold">{team.name}</h4>
                  <p>النقاط: {team.score}</p>
                  <p>الأسئلة المجابة: {team.questions_answered}</p>
                  <p>المساعدات المستخدمة: {team.help_options_used}</p>
                </div>
              ))}
            </div>
            <div className="mt-6">
              <button 
                onClick={goToHome}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
              >
                العودة للرئيسية
              </button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default GamePage;
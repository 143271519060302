/**
 * MediaHandler utility for handling media files
 */
const MediaHandler = {
  /**
   * Determines the type of media file from the URL or data URI
   * @param {string} file - The file URL or data URI
   * @returns {string|null} - The media type ('image', 'video', 'audio') or null if unknown
   */
  getFileType(file) {
    if (!file) return null;

    if (file.startsWith('data:')) {
      if (file.includes('data:image/')) return 'image';
      if (file.includes('data:video/')) return 'video';
      if (file.includes('data:audio/')) return 'audio';
      return null;
    }

    const extension = file.split('.').pop().toLowerCase();
    
    const mediaTypes = {
      image: ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'bmp',''],
      video: ['mp4', 'webm', 'ogg', 'mov', 'm4v', '3gp', 'm3u8'],
      audio: ['mp3', 'wav', 'ogg', 'aac', 'm4a', '3gp']
    };

    for (const [type, extensions] of Object.entries(mediaTypes)) {
      if (extensions.includes(extension)) {
        return type;
      }
    }

    return null;
  },

  /**
   * Preloads media to ensure it's available when needed
   * @param {string} url - The media URL to preload
   * @returns {Promise} - A promise that resolves when the media is preloaded
   */
  preloadMedia(url) {
    if (!url) return Promise.resolve();

    return new Promise((resolve, reject) => {
      const fileType = this.getFileType(url);

      if (fileType === 'image') {
        const img = new Image();
        img.onload = () => resolve(url);
        img.onerror = () => reject(new Error(`Failed to load image: ${url}`));
        img.src = url;
      } else if (fileType === 'video' || fileType === 'audio') {
        const element = document.createElement(fileType);
        element.preload = 'metadata';
        if (fileType === 'video') {
          element.playsinline = true;
          element.webkitPlaysinline = true;
        }
        element.onloadedmetadata = () => resolve(url);
        element.onerror = () => reject(new Error(`Failed to load ${fileType}: ${url}`));
        element.src = url;
      } else {
        resolve(url);
      }
    });
  },

  /**
   * Stops all media elements that are currently playing
   */
  stopAllMedia() {
    document.querySelectorAll('video, audio').forEach(media => {
      try {
        media.pause();
        media.currentTime = 0;
        if (!media.hasAttribute('data-original-src')) {
          media.setAttribute('data-original-src', media.src);
        }
      } catch (e) {
        console.error('Error stopping media:', e);
      }
    });
  },

  /**
   * Restores original sources for media elements
   */
  restoreMediaSources() {
    document.querySelectorAll('video, audio').forEach(media => {
      try {
        const originalSrc = media.getAttribute('data-original-src');
        if (originalSrc && (!media.src || media.src !== originalSrc)) {
          media.src = originalSrc;
          media.load();
        }
      } catch (e) {
        console.error('Error restoring media source:', e);
      }
    });
  },

  /**
   * Preloads all media files in an array of categories
   * @param {Array} categories - Array of category objects with image properties
   * @returns {Promise} - A promise that resolves when all media is preloaded
   */
  async preloadAllMedia(categories) {
    if (!categories?.length) return;
    
    const mediaPromises = categories
      .map(category => category.image)
      .filter(Boolean)
      .map(image => this.preloadMedia(image));

    await Promise.all(mediaPromises);
  }
};

export default MediaHandler;
import React, { useState } from 'react';
import { useOffline } from '../../contexts/OfflineContext';

const OfflineStatus = ({ showDetails = false }) => {
  const { 
    isOnline, 
    isOfflineMode, 
    toggleOfflineMode, 
    pendingActions, 
    syncPendingActions,
    isSyncing,
    syncProgress
  } = useOffline();
  
  const [showPendingActions, setShowPendingActions] = useState(false);

  // Get status color based on connection state
  const getStatusColor = () => {
    if (!isOnline) {
      return 'bg-red-500';
    }
    if (isOfflineMode) {
      return 'bg-yellow-500';
    }
    if (pendingActions.length > 0) {
      return 'bg-orange-400';
    }
    return 'bg-green-500';
  };

  // Get status text
  const getStatusText = () => {
    if (!isOnline) {
      return 'غير متصل';
    }
    if (isOfflineMode) {
      return 'وضع عدم الاتصال';
    }
    if (pendingActions.length > 0) {
      return 'بانتظار المزامنة';
    }
    return 'متصل';
  };

  // Format action timestamp
  const formatActionTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString('ar-SA');
  };

  // Format action name for display
  const formatActionName = (action) => {
    const actionNames = {
      'record_answer': 'تسجيل إجابة',
      'record_answer_no_one': 'تسجيل إجابة (لا أحد)',
      'update_team_score': 'تحديث نقاط الفريق',
      'end_game': 'إنهاء اللعبة',
      'complaints': 'شكوى',
      'use_help_option': 'استخدام مساعدة'
    };
    
    return actionNames[action] || action;
  };

  if (!showDetails) {
    // Minimal version for navbar
    return (
      <div className="flex items-center">
        <div 
          className={`w-2 h-2 rounded-full ${getStatusColor()}`} 
          title={getStatusText()}
        ></div>
      </div>
    );
  }

  // Detailed version
  return (
    <div className="bg-white rounded-lg shadow p-4 mb-6">
      <h3 className="text-xl font-bold mb-3">حالة الاتصال</h3>
      
      <div className="flex items-center justify-between mb-3">
        <span className="text-gray-700">الحالة:</span>
        <div className="flex items-center">
          <div className={`w-3 h-3 rounded-full ${getStatusColor()} mr-2`}></div>
          <span className="font-medium">{getStatusText()}</span>
        </div>
      </div>
      
      <div className="flex items-center justify-between mb-4">
        <span className="text-gray-700">وضع عدم الاتصال:</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input 
            type="checkbox" 
            checked={isOfflineMode}
            onChange={() => toggleOfflineMode()}
            disabled={!isOnline && !isOfflineMode}
            className="sr-only peer" 
          />
          <div className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600 ${!isOnline && !isOfflineMode ? 'opacity-50 cursor-not-allowed' : ''}`}></div>
        </label>
      </div>
      
      {pendingActions.length > 0 && (
        <div className="mb-4">
          <div className="flex items-center justify-between mb-2">
            <span className="text-gray-700">عمليات معلقة:</span>
            <span className="font-medium text-orange-500">{pendingActions.length}</span>
          </div>
          
          <button
            onClick={() => setShowPendingActions(!showPendingActions)}
            className="text-blue-500 text-sm hover:text-blue-700 mb-2 flex items-center"
          >
            <i className={`fas fa-chevron-${showPendingActions ? 'up' : 'down'} mr-1`}></i>
            {showPendingActions ? 'إخفاء التفاصيل' : 'عرض التفاصيل'}
          </button>
          
          {showPendingActions && (
            <div className="max-h-40 overflow-y-auto bg-gray-50 p-2 rounded text-sm mb-2">
              <ul className="divide-y divide-gray-200">
                {pendingActions.map((action) => (
                  <li key={action.id} className="py-2">
                    <div className="font-medium">{formatActionName(action.action)}</div>
                    <div className="text-xs text-gray-500">{formatActionTimestamp(action.timestamp)}</div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          
          {isOnline && (
            <button
              onClick={syncPendingActions}
              disabled={isSyncing || !isOnline}
              className={`w-full font-medium py-2 px-4 rounded transition flex items-center justify-center gap-2 ${
                isSyncing || !isOnline
                  ? 'bg-gray-400 cursor-not-allowed text-white'
                  : 'bg-orange-500 hover:bg-orange-600 text-white'
              }`}
            >
              {isSyncing ? (
                <>
                  <svg className="animate-spin -mr-1 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  جاري المزامنة... {syncProgress}%
                </>
              ) : (
                <>
                  <i className="fas fa-sync"></i>
                  مزامنة الإجراءات المعلقة
                </>
              )}
            </button>
          )}
        </div>
      )}
      
      <button 
        onClick={() => toggleOfflineMode(!isOfflineMode)}
        disabled={!isOnline && !isOfflineMode}
        className={`w-full font-medium py-2 px-4 rounded transition ${
          !isOnline && !isOfflineMode
            ? 'bg-gray-400 cursor-not-allowed text-white'
            : isOfflineMode
              ? 'bg-yellow-500 hover:bg-yellow-600 text-white'
              : 'bg-blue-500 hover:bg-blue-600 text-white'
        }`}
      >
        {isOfflineMode ? 'تعطيل وضع عدم الاتصال' : 'تفعيل وضع عدم الاتصال'}
      </button>
      
      <div className="mt-4 text-xs text-gray-500 text-center">
        {isOfflineMode ? (
          'وضع عدم الاتصال مفعل. ستتم مزامنة الإجراءات عند الاتصال بالإنترنت مرة أخرى.'
        ) : pendingActions.length > 0 ? (
          'يوجد إجراءات معلقة بانتظار المزامنة مع الخادم.'
        ) : (
          'جميع البيانات متزامنة مع الخادم.'
        )}
      </div>
    </div>
  );
};

export default OfflineStatus;
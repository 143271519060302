import React, { useState } from 'react';
import { useGame } from '../../contexts/GameContext';
import Modal from '../common/Modal';

const TeamFooter = () => {
  const { 
    teams, 
    currentTeamIndex, 
    adjustScore, 
    // Rename to avoid ESLint hook rules confusion
    useHelpOption: applyHelpOption, 
    currentQuestion 
  } = useGame();
  
  const [showHelpOptions, setShowHelpOptions] = useState(false);
  const [selectedTeamForHelp, setSelectedTeamForHelp] = useState(null);

  const handleHelpOptionClick = (teamIndex) => {
    setSelectedTeamForHelp(teamIndex);
    setShowHelpOptions(true);
  };

  const handleUseHelpOption = (optionId) => {
    // Call the renamed function
    applyHelpOption(optionId);
    setShowHelpOptions(false);
  };

  // Check if help option is available
  const canUseHelpOption = (option, teamIndex) => {
    return !option.used && 
      currentTeamIndex === teamIndex &&
      option.active && 
      option.points_cost <= teams[teamIndex].score &&
      (!option.requires_question || currentQuestion) &&
      (!option.during_question || currentQuestion) &&
      (option.during_question || !currentQuestion);
  };

  return (
    <>
      <footer className="flex-shrink-0 bg-gradient-to-r from-teal-400 to-blue-500 text-white shadow-md p-2">
        {/* Portrait Layout (Mobile) */}
        <div className="portrait:block landscape:hidden">
          <div className="grid grid-cols-2 gap-1 w-full">
            {teams.map((team, index) => (
              <div 
                key={index}
                className={`flex flex-col items-center bg-black/10 rounded-lg p-1 ${
                  currentTeamIndex === index ? 'ring-2 ring-yellow-400' : ''
                }`}
              >
                {/* Team Header */}
                <div className="flex items-center justify-between w-full mb-2">
                  <span className="font-semibold text-2xl">{team.name}</span>
                  {/* Score Controls */}
                  <div className="flex items-center">
                    <button 
                      onClick={() => adjustScore(index, -50)} 
                      className="bg-white text-red-800 p-1 py-1.5 rounded-r hover:bg-gray-100 transition-colors"
                    >
                      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px">
                        <path d="M1 10L1 6L15 6V10L1 10Z" fill="#4A90E2"></path>
                      </svg>
                    </button>
                    <span 
                      className={`team-${index}-score bg-white text-[#4A90E2] w-[60px] text-2xl text-center font-semibold px-1`}
                    >
                      {team.score}
                    </span>
                    <button 
                      onClick={() => adjustScore(index, 50)} 
                      className="bg-white text-green-700 p-1 py-1.5 rounded-l hover:bg-gray-100 transition-colors"
                    >
                      <svg width="20px" height="20px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 1H6V6L1 6V10H6V15H10V10H15V6L10 6V1Z" fill="#4A90E2"></path>
                      </svg>
                    </button>
                  </div>
                </div>
                
                {/* Help Options */}
                <div className="w-full">
                  <div className="grid grid-cols-2 gap-1">
                    {team.helpOptions.slice(0, 4).map(option => (
                      <button 
                        key={option.id}
                        onClick={() => canUseHelpOption(option, index) && handleHelpOptionClick(index)}
                        disabled={!canUseHelpOption(option, index)}
                        className={`flex items-center justify-center gap-1 px-2 py-1.5 
                            bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400 
                            text-white rounded-md transition-colors text-2xl ${
                              option.used ? 'opacity-50' : ''
                            }`}
                      >
                        <i className={option.icon}></i>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {/* Landscape Layout */}
        <div className="landscape:flex portrait:hidden">
          <div className="flex justify-between w-full gap-8">
            {teams.map((team, index) => (
              <div 
                key={index}
                className={`flex items-center space-x-4 space-x-reverse bg-black/10 rounded-lg p-2 ${
                  currentTeamIndex === index ? 'ring-2 ring-yellow-400' : ''
                }`}
              >
                {/* Team Name and Score */}
                <div className="flex items-center gap-1">
                  <span className="font-semibold text-lg whitespace-nowrap">{team.name}</span>
                  <div className="flex items-center">
                    <button 
                      onClick={() => adjustScore(index, -50)} 
                      className="bg-white text-red-800 p-1 rounded-r hover:bg-gray-100 transition-colors"
                    >
                      <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px">
                        <path d="M1 10L1 6L15 6V10L1 10Z" fill="#4A90E2"></path>
                      </svg>
                    </button>
                    <span 
                      className={`team-${index}-score bg-white text-[#4A90E2] w-[60px] text-xl text-center font-semibold px-1`}
                    >
                      {team.score}
                    </span>
                    <button 
                      onClick={() => adjustScore(index, 50)} 
                      className="bg-white text-green-700 p-1 rounded-l hover:bg-gray-100 transition-colors"
                    >
                      <svg width="20px" height="20px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 1H6V6L1 6V10H6V15H10V10H15V6L10 6V1Z" fill="#4A90E2"></path>
                      </svg>
                    </button>
                  </div>
                </div>
                
                {/* Help Options */}
                <div className="flex gap-1">
                  {team.helpOptions.slice(0, 4).map(option => (
                    <button 
                      key={option.id}
                      onClick={() => canUseHelpOption(option, index) && handleHelpOptionClick(index)}
                      disabled={!canUseHelpOption(option, index)}
                      title={option.name}
                      className={`flex items-center gap-1 px-2 py-1 
                          bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400 
                          text-white rounded-md transition-colors text-sm ${
                            option.used ? 'opacity-50' : ''
                          }`}
                    >
                      <i className={option.icon}></i>
                    </button>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </footer>

      {/* Help Options Modal */}
      <Modal
        isOpen={showHelpOptions}
        onClose={() => setShowHelpOptions(false)}
        title="وسائل المساعدة المتاحة"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {selectedTeamForHelp !== null && teams[selectedTeamForHelp].helpOptions.map(option => {
            const isDisabled = option.used || 
              !option.active || 
              option.points_cost > teams[selectedTeamForHelp].score ||
              (option.requires_question && !currentQuestion) ||
              (option.during_question && !currentQuestion) ||
              (!option.during_question && currentQuestion);
            
            const disabledReason = option.used ? 'تم الاستخدام' :
              !option.active ? 'غير متاح' :
              option.points_cost > teams[selectedTeamForHelp].score ? 'نقاط غير كافية' :
              option.requires_question && !currentQuestion ? 'يتطلب وجود سؤال' :
              option.during_question && !currentQuestion ? 'متاح فقط أثناء السؤال' :
              !option.during_question && currentQuestion ? 'غير متاح أثناء السؤال' : '';
            
            return (
              <div key={option.id} className={isDisabled ? 'opacity-50' : ''}>
                <div className="help-option-card bg-white rounded-lg shadow-md relative flex flex-col space-y-2">
                  <button 
                    onClick={() => !isDisabled && handleUseHelpOption(option.id)}
                    type="button"
                    className="w-full bg-blue-500 hover:bg-blue-600 disabled:bg-gray-400 
                              text-white py-2 px-4 rounded transition-colors"
                    disabled={isDisabled}
                  >
                    <div className="flex items-center">
                      <i className={`${option.icon} text-2xl ml-2 px-2`}></i>
                      <h4 className="font-bold">{option.name}</h4>
                      {isDisabled && (
                        <div className="text-xs text-red-500 text-center mt-1">
                          {disabledReason}
                        </div>
                      )}
                    </div>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default TeamFooter;
import React, { createContext, useContext, useState, useEffect, useRef, useCallback } from 'react';
import apiService from '../api/apiService';
import { useAuth } from './AuthContext';

// Create a context
export const PingContext = createContext();

// Ping interval in milliseconds (5 minutes)
const PING_INTERVAL = 5 * 60 * 1000;
// Failure retry interval (30 seconds)
const RETRY_INTERVAL = 30 * 1000;
// Max consecutive failures before degrading interval (5 failures)
const MAX_FAILURES = 5;

export const PingProvider = ({ children }) => {
  const { isLoggedIn, updateUserData } = useAuth();
  const [pingStatus, setPingStatus] = useState('idle');
  const [lastPingTime, setLastPingTime] = useState(null);
  const [consecutiveFailures, setConsecutiveFailures] = useState(0);
  const pingIntervalRef = useRef(null);
  const retryTimeoutRef = useRef(null);
  const pingEnabled = useRef(true);
  const currentInterval = useRef(PING_INTERVAL);

  // Function to perform a ping
  const performPing = useCallback(async () => {
    if (!pingEnabled.current || !isLoggedIn) return;

    try {
      setPingStatus('pinging');
      // Clear any existing retry timeout
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current);
        retryTimeoutRef.current = null;
      }

      // Use the dedicated ping method
      const response = await apiService.ping();
      
      const currentTime = new Date().toISOString();
      setLastPingTime(currentTime);
      
      if (response.data.success) {
        setPingStatus('success');
        // Reset failure count on success
        setConsecutiveFailures(0);
        // Reset to normal interval if we were in retry mode
        if (currentInterval.current !== PING_INTERVAL) {
          currentInterval.current = PING_INTERVAL;
          // Restart the ping service with the normal interval
          if (pingIntervalRef.current) {
            clearInterval(pingIntervalRef.current);
            pingIntervalRef.current = setInterval(performPing, PING_INTERVAL);
          }
        }
        // Update user data if there are any changes
        if (response.data.data) {
          updateUserData(response.data.data);
        }
      } else {
        handlePingFailure('Response indicates failure');
      }
    } catch (error) {
      handlePingFailure(error);
    }
  }, [isLoggedIn, updateUserData]);

  // Handle ping failures
  const handlePingFailure = (error) => {
    setPingStatus('failed');
    setLastPingTime(new Date().toISOString());
    
    // Increment failure count
    setConsecutiveFailures(prev => prev + 1);
    
    // Log the error (but don't expose to user)
    console.log('Ping failed:', error);
    
    // Schedule a retry
    retryTimeoutRef.current = setTimeout(() => {
      performPing();
    }, RETRY_INTERVAL);
  };

  // Start the ping interval
  const startPingService = useCallback(() => {
    if (pingIntervalRef.current !== null) return;
    
    // Run first ping immediately
    performPing();
    
    // Set interval for future pings
    pingIntervalRef.current = setInterval(performPing, currentInterval.current);
    console.log('API Ping service started with interval:', currentInterval.current / 1000, 'seconds');
  }, [performPing]);

  // Stop the ping interval
  const stopPingService = useCallback(() => {
    if (pingIntervalRef.current !== null) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = null;
    }
    
    if (retryTimeoutRef.current !== null) {
      clearTimeout(retryTimeoutRef.current);
      retryTimeoutRef.current = null;
    }
    
    setPingStatus('stopped');
    console.log('API Ping service stopped');
  }, []);

  // Enable or disable ping
  const togglePingEnabled = useCallback((enabled) => {
    pingEnabled.current = enabled;
    
    if (enabled && pingIntervalRef.current === null && isLoggedIn) {
      startPingService();
    } else if (!enabled && pingIntervalRef.current !== null) {
      stopPingService();
    }
  }, [isLoggedIn, startPingService, stopPingService]);

  // When consecutive failures exceed threshold, adjust ping strategy
  useEffect(() => {
    if (consecutiveFailures >= MAX_FAILURES) {
      console.log(`Exceeded maximum consecutive failures (${MAX_FAILURES}), degrading ping frequency`);
      
      // Update the interval to be longer to reduce server load
      currentInterval.current = PING_INTERVAL * 2;
      
      // Restart the ping service with the new interval
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
        pingIntervalRef.current = setInterval(performPing, currentInterval.current);
      }
    }
  }, [consecutiveFailures, performPing]);

  // Handle visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible' && isLoggedIn) {
        // Check if it's been too long since the last ping
        if (lastPingTime) {
          const now = new Date();
          const lastPing = new Date(lastPingTime);
          const timeSinceLastPing = now - lastPing;
          
          // If it's been more than 1.5x the interval, perform a ping
          if (timeSinceLastPing > currentInterval.current * 1.5) {
            console.log('Performing ping after visibility change');
            performPing();
          }
        } else {
          // If no ping has been done yet, do one
          performPing();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [lastPingTime, performPing, isLoggedIn]);

  // Start/stop ping service based on login status
  useEffect(() => {
    if (isLoggedIn && pingEnabled.current) {
      startPingService();
    } else {
      stopPingService();
    }
    
    // Cleanup on unmount
    return () => {
      if (pingIntervalRef.current !== null) {
        clearInterval(pingIntervalRef.current);
      }
      if (retryTimeoutRef.current !== null) {
        clearTimeout(retryTimeoutRef.current);
      }
    };
  }, [isLoggedIn, startPingService, stopPingService]);

  // Provide context value
  const value = {
    pingStatus,
    lastPingTime,
    consecutiveFailures,
    performPing,
    togglePingEnabled,
    isPingEnabled: pingEnabled.current,
    currentInterval: currentInterval.current
  };

  return (
    <PingContext.Provider value={value}>
      {children}
    </PingContext.Provider>
  );
};

// Custom hook to use the ping context
export const usePing = () => {
  const context = useContext(PingContext);
  if (!context) {
    throw new Error('usePing must be used within a PingProvider');
  }
  return context;
};
import React from 'react';
import { useGame } from '../../contexts/GameContext';

const CategoryGrid = () => {
  const { 
    categories, 
    selectQuestion, 
    isCategoryAvailable, 
    getQuestionCount,
    getPointsForClass
  } = useGame();

  const difficultiesArray = ['CLASS_50', 'CLASS_150', 'CLASS_300'];

  return (
    <div className="categories-grid grid gap-1 overflow-y-auto flex-1 landscape:grid-cols-3 portrait:grid-cols-2">
      {categories.map(category => (
        <div key={category.id} className="category-item relative grid overflow-hidden">
          <div>
            <div 
              className="category-image flex-1 bg-cover bg-center min-h-full" 
              style={{ backgroundImage: `url(${category.image})` }}
            >
              <div 
                className={`category-title bg-[rgba(84,159,226,0.5)] text-white landscape:text-right text-center p-1 font-bold 
                  text-sm sm:text-base md:text-lg lg:text-xl xl:text-lg 2xl:text-xl 4xl:text-2xl landscape:sm:text-sm landscape:md:text-base 
                  landscape:xl:text-lg whitespace-nowrap overflow-hidden ${
                    category.name.length > 30 
                      ? 'text-xs sm:text-xs md:text-sm lg:text-base xl:text-sm 2xl:text-base 4xl:text-lg' 
                      : 'text-sm sm:text-base md:text-lg lg:text-xl xl:text-lg 2xl:text-xl 4xl:text-2xl landscape:sm:text-sm landscape:md:text-base landscape:xl:text-lg'
                  }`}
              >
                {category.name}
              </div>
            </div>
          </div>
          <div className="difficulty-buttons absolute landscape:bottom-0 portrait:inset-y-0 left-0 flex flex-col justify-center font-bold text-[0.9rem] sm:text-base md:text-lg lg:text-xl xl:text-2xl gap-2 w-[4.2rem] sm:w-[5rem] md:w-[6rem] lg:w-[7rem] xl:w-[8rem]">
            {difficultiesArray.map(difficulty => (
              <button 
                key={difficulty}
                onClick={() => selectQuestion(category.id, difficulty)} 
                className="relative bg-[#4A90E2] text-white rounded-full text-xl sm:text-2xl md:text-3xl lg:text-4xl disabled:opacity-50 disabled:cursor-not-allowed"
                disabled={!isCategoryAvailable(category.id, difficulty)}
              >
                <span 
                  className={`float-right mt-[5px] ${
                    difficulty === 'CLASS_50' 
                      ? 'mr-[10px] sm:mr-[12px] md:mr-[14px] lg:mr-[16px]' 
                      : difficulty === 'CLASS_150' 
                        ? 'mr-[7px] sm:mr-[9px] md:mr-[11px] lg:mr-[13px]' 
                        : 'mr-[5px] sm:mr-[7px] md:mr-[9px] lg:mr-[11px]'
                  }`}
                >
                  {difficulty.split('_')[1]}
                </span>
                <div 
                  style={{ height: '100%' }} 
                  className="absolute top-0 left-0 bg-yellow-500 text-white text px-1 rounded-none flex items-center justify-center"
                >
                  <span>
                    {getQuestionCount(category.id, difficulty) !== null 
                      ? getQuestionCount(category.id, difficulty) || '-' 
                      : '-'}
                  </span>
                </div>
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryGrid;
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../api/apiService';
import { toast } from 'react-toastify';
import Loader from '../components/common/Loader';

const CreateGamePage = () => {
  const { isLoggedIn, userData } = useAuth();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [categories, setCategories] = useState([]);
  const [helpOptions, setHelpOptions] = useState([]);
  
  const [formData, setFormData] = useState({
    name: '',
    team1_name: 'الفريق الأول',
    team2_name: 'الفريق الثاني',
    help_system: 'fixed',
    category_ids: [],
    team1_help_options: [],
    team2_help_options: []
  });
  
  const [errors, setErrors] = useState({});
  
  // Fetch categories and help options
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Redirect if not logged in
        if (!isLoggedIn) {
          navigate('/');
          return;
        }
        
        // Check if user has games remaining
        if (userData.games_remaining <= 0) {
          toast.error('ليس لديك ألعاب متبقية. يرجى ترقية حسابك.');
          navigate('/');
          return;
        }
        
        setLoading(true);
        
        const [categoriesResponse, helpOptionsResponse] = await Promise.all([
          apiService.getCategories(),
          apiService.getHelpOptions()
        ]);
        
        if (categoriesResponse.data.success) {
          setCategories(categoriesResponse.data.data);
        }
        
        if (helpOptionsResponse.data.success) {
          setHelpOptions(helpOptionsResponse.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('حدث خطأ أثناء تحميل البيانات');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [isLoggedIn, userData, navigate]);
  
  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };
  
  // Toggle category selection
  const toggleCategory = (categoryId) => {
    setFormData(prev => {
      const categoryIds = [...prev.category_ids];
      const index = categoryIds.indexOf(categoryId);
      
      if (index === -1) {
        if (categoryIds.length < 6) {
          return { ...prev, category_ids: [...categoryIds, categoryId] };
        } else {
          toast.warning('لا يمكن اختيار أكثر من 6 تصنيفات');
          return prev;
        }
      } else {
        return { ...prev, category_ids: categoryIds.filter(id => id !== categoryId) };
      }
    });
    
    // Clear error if any categories are selected
    if (errors.category_ids) {
      setErrors(prev => ({ ...prev, category_ids: '' }));
    }
  };
  
  // Toggle help option selection for team
  const toggleHelpOption = (optionId, team) => {
    const field = team === 1 ? 'team1_help_options' : 'team2_help_options';
    
    setFormData(prev => {
      const options = [...prev[field]];
      const index = options.indexOf(optionId);
      
      if (index === -1) {
        if (options.length < 4) {
          return { ...prev, [field]: [...options, optionId] };
        } else {
          toast.warning('لا يمكن اختيار أكثر من 4 مساعدات للفريق الواحد');
          return prev;
        }
      } else {
        return { ...prev, [field]: options.filter(id => id !== optionId) };
      }
    });
    
    // Clear error if any help options are selected
    if (errors[field]) {
      setErrors(prev => ({ ...prev, [field]: '' }));
    }
  };
  
  // Select help system
  const selectHelpSystem = (system) => {
    setFormData(prev => ({ 
      ...prev, 
      help_system: system,
      // Reset help options when changing system
      team1_help_options: [],
      team2_help_options: []
    }));
    
    if (system === 'random') {
      assignRandomHelpOptions();
    }
  };
  
  // Assign random help options
  const assignRandomHelpOptions = () => {
    const availableOptions = helpOptions.filter(option => option.active);
    
    // Ensure we have enough options
    if (availableOptions.length < 8) {
      toast.error('لا توجد مساعدات كافية للاختيار العشوائي');
      return;
    }
    
    // Shuffle options
    const shuffled = [...availableOptions].sort(() => Math.random() - 0.5);
    
    // Assign 4 options to each team
    setFormData(prev => ({
      ...prev,
      team1_help_options: shuffled.slice(0, 4).map(opt => opt.id),
      team2_help_options: shuffled.slice(4, 8).map(opt => opt.id)
    }));
  };
  
  // Validate form
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'اسم اللعبة مطلوب';
    } else if (formData.name.length > 30) {
      newErrors.name = 'اسم اللعبة يجب ألا يتجاوز 30 حرفاً';
    }
    
    if (!formData.team1_name.trim()) {
      newErrors.team1_name = 'اسم الفريق الأول مطلوب';
    }
    
    if (!formData.team2_name.trim()) {
      newErrors.team2_name = 'اسم الفريق الثاني مطلوب';
    }
    
    if (formData.team1_name === formData.team2_name) {
      newErrors.team2_name = 'يجب أن يكون اسم الفريق الثاني مختلفاً عن الفريق الأول';
    }
    
    if (formData.category_ids.length !== 6) {
      newErrors.category_ids = 'يجب اختيار 6 تصنيفات';
    }
    
    if (formData.help_system === 'fixed') {
      if (formData.team1_help_options.length !== 4) {
        newErrors.team1_help_options = 'يجب اختيار 4 مساعدات للفريق الأول';
      }
      if (formData.team2_help_options.length !== 4) {
        newErrors.team2_help_options = 'يجب اختيار 4 مساعدات للفريق الثاني';
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      toast.error('يرجى تصحيح الأخطاء قبل إنشاء اللعبة');
      return;
    }
    
    try {
      setSubmitting(true);
      
      const response = await apiService.createGame(formData);
      
      if (response.data.success) {
        toast.success('تم إنشاء اللعبة بنجاح');
        // Navigate to game page
        navigate(`/play/${response.data.data.game_id}`);
      } else {
        toast.error(response.data.message || 'فشل في إنشاء اللعبة');
      }
    } catch (error) {
      console.error('Error creating game:', error);
      
      if (error.response?.status === 422) {
        // Validation errors
        setErrors(error.response.data.errors || {});
      } else if (error.response?.status === 403) {
        // Not enough games remaining
        toast.error('ليس لديك ألعاب متبقية. يرجى ترقية حسابك.');
        navigate('/');
      } else {
        toast.error(error.response?.data?.message || 'حدث خطأ أثناء إنشاء اللعبة');
      }
    } finally {
      setSubmitting(false);
    }
  };
  
  if (loading) {
    return <Loader text="جاري تحميل البيانات..." />;
  }
  
  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="bg-gradient-to-r from-teal-400 to-blue-500 p-6">
            <h1 className="text-2xl font-bold text-white">إنشاء لعبة جديدة</h1>
          </div>
          
          <form onSubmit={handleSubmit} className="p-6">
            <div className="space-y-6">
              {/* Game Name */}
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  اسم اللعبة
                </label>
                <input 
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                    errors.name ? 'border-red-500' : ''
                  }`}
                  maxLength="30"
                  placeholder="أدخل اسم اللعبة"
                  required
                />
                <div className="flex justify-between mt-1">
                  {errors.name && (
                    <p className="text-red-500 text-sm">{errors.name}</p>
                  )}
                  <p className="text-gray-500 text-sm">
                    <span>{formData.name.length}</span>/30
                  </p>
                </div>
              </div>
              
              {/* Team Names */}
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    اسم الفريق الأول
                  </label>
                  <input 
                    type="text"
                    name="team1_name"
                    value={formData.team1_name}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                      errors.team1_name ? 'border-red-500' : ''
                    }`}
                    required
                  />
                  {errors.team1_name && (
                    <p className="text-red-500 text-sm mt-1">{errors.team1_name}</p>
                  )}
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    اسم الفريق الثاني
                  </label>
                  <input 
                    type="text"
                    name="team2_name"
                    value={formData.team2_name}
                    onChange={handleInputChange}
                    className={`w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                      errors.team2_name ? 'border-red-500' : ''
                    }`}
                    required
                  />
                  {errors.team2_name && (
                    <p className="text-red-500 text-sm mt-1">{errors.team2_name}</p>
                  )}
                </div>
              </div>
              
              {/* Help System Selection */}
              <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  نظام المساعدات
                </label>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <button 
                    type="button"
                    onClick={() => selectHelpSystem('fixed')}
                    className={`p-4 rounded-lg ${
                      formData.help_system === 'fixed' 
                        ? 'bg-blue-500 text-white' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    <i className="fas fa-lock text-2xl mb-2"></i>
                    <div className="font-bold">مساعدات محددة</div>
                    <div className="text-sm">اختر المساعدات لكل فريق</div>
                  </button>
                  
                  <button 
                    type="button"
                    onClick={() => selectHelpSystem('random')}
                    className={`p-4 rounded-lg ${
                      formData.help_system === 'random' 
                        ? 'bg-blue-500 text-white' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    <i className="fas fa-random text-2xl mb-2"></i>
                    <div className="font-bold">مساعدات عشوائية</div>
                    <div className="text-sm">اختيار عشوائي للمساعدات</div>
                  </button>
                  
                  <button 
                    type="button"
                    onClick={() => selectHelpSystem('team_choice')}
                    className={`p-4 rounded-lg ${
                      formData.help_system === 'team_choice' 
                        ? 'bg-blue-500 text-white' 
                        : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                    }`}
                  >
                    <i className="fas fa-users text-2xl mb-2"></i>
                    <div className="font-bold">اختيار الفريق</div>
                    <div className="text-sm">يختار كل فريق مساعداته</div>
                  </button>
                </div>
              </div>
              
              {/* Categories Selection */}
              <div>
                <div className="flex justify-between items-center mb-2">
                  <label className="block text-gray-700 text-sm font-bold">
                    اختر التصنيفات (6 تصنيفات)
                  </label>
                  <span className="text-sm text-gray-600">
                    تم اختيار <span className="font-bold text-blue-600">{formData.category_ids.length}</span>/6
                  </span>
                </div>
                
                {errors.category_ids && (
                  <p className="text-red-500 text-sm mb-2">{errors.category_ids}</p>
                )}
                
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                  {categories.map(category => (
                    <div 
                      key={category.id}
                      className={`category-card bg-white border rounded-lg overflow-hidden hover:shadow-md transition cursor-pointer ${
                        formData.category_ids.includes(category.id) ? 'border-2 border-blue-500' : 'border-gray-200'
                      } ${
                        formData.category_ids.length >= 6 && !formData.category_ids.includes(category.id) ? 'opacity-50' : ''
                      }`}
                      onClick={() => toggleCategory(category.id)}
                    >
                      <div className="h-24">
                        <img 
                          src={category.image} 
                          alt={category.name}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      <div className="p-2 text-center">
                        <h3 className="text-sm font-medium truncate">{category.name}</h3>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Help Options Selection (If fixed) */}
              {formData.help_system === 'fixed' && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* Team 1 Help Options */}
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <label className="block text-gray-700 text-sm font-bold">
                        مساعدات {formData.team1_name}
                      </label>
                      <span className="text-sm text-gray-600">
                        تم اختيار <span className="font-bold text-blue-600">{formData.team1_help_options.length}</span>/4
                      </span>
                    </div>
                    
                    {errors.team1_help_options && (
                      <p className="text-red-500 text-sm mb-2">{errors.team1_help_options}</p>
                    )}
                    
                    <div className="grid grid-cols-2 gap-2">
                      {helpOptions.map(option => (
                        <div 
                          key={option.id}
                          className={`p-2 border rounded-lg cursor-pointer ${
                            formData.team1_help_options.includes(option.id) 
                              ? 'border-blue-500 bg-blue-50' 
                              : 'border-gray-200 hover:bg-gray-50'
                          } ${
                            formData.team1_help_options.length >= 4 && !formData.team1_help_options.includes(option.id) 
                              ? 'opacity-50' 
                              : ''
                          }`}
                          onClick={() => toggleHelpOption(option.id, 1)}
                        >
                          <div className="flex items-center gap-2">
                            <i className={`${option.icon} text-blue-500`}></i>
                            <span className="text-sm font-medium">{option.name}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  
                  {/* Team 2 Help Options */}
                  <div>
                    <div className="flex justify-between items-center mb-2">
                      <label className="block text-gray-700 text-sm font-bold">
                        مساعدات {formData.team2_name}
                      </label>
                      <span className="text-sm text-gray-600">
                        تم اختيار <span className="font-bold text-blue-600">{formData.team2_help_options.length}</span>/4
                      </span>
                    </div>
                    
                    {errors.team2_help_options && (
                      <p className="text-red-500 text-sm mb-2">{errors.team2_help_options}</p>
                    )}
                    
                    <div className="grid grid-cols-2 gap-2">
                      {helpOptions.map(option => (
                        <div 
                          key={option.id}
                          className={`p-2 border rounded-lg cursor-pointer ${
                            formData.team2_help_options.includes(option.id) 
                              ? 'border-green-500 bg-green-50' 
                              : 'border-gray-200 hover:bg-gray-50'
                          } ${
                            formData.team2_help_options.length >= 4 && !formData.team2_help_options.includes(option.id) 
                              ? 'opacity-50' 
                              : ''
                          }`}
                          onClick={() => toggleHelpOption(option.id, 2)}
                        >
                          <div className="flex items-center gap-2">
                            <i className={`${option.icon} text-green-500`}></i>
                            <span className="text-sm font-medium">{option.name}</span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              
              {/* Submit Button */}
              <div className="flex justify-end gap-4">
                <button 
                  type="button"
                  onClick={() => navigate('/')}
                  className="px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition"
                >
                  إلغاء
                </button>
                <button 
                  type="submit"
                  className="px-6 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
                  disabled={submitting}
                >
                  {submitting ? (
                    <span className="flex items-center justify-center gap-2">
                      <i className="fas fa-spinner fa-spin"></i>
                      جاري الإنشاء...
                    </span>
                  ) : (
                    'إنشاء اللعبة'
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateGamePage;
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Contexts
import { AuthProvider } from './contexts/AuthContext';
import { GameProvider } from './contexts/GameContext';
import { PingProvider } from './contexts/PingContext';
import { OfflineProvider } from './contexts/OfflineContext';

// Pages
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import GamesHistoryPage from './pages/GamesHistoryPage';
import GameDetailsPage from './pages/GameDetailsPage';
import ActivationPage from './pages/ActivationPage';
import CreateGamePage from './pages/CreateGamePage';
import GamePage from './pages/GamePage';

// Protected route component
const ProtectedRoute = ({ children }) => {
  // Check if user is logged in from localStorage or session
  const isAuthenticated = localStorage.getItem('isLoggedIn') === 'true' || 
                          sessionStorage.getItem('isLoggedIn') === 'true';
  
  if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/login" replace />;
  }
  
  return children;
};

function App() {
  return (
    <AuthProvider>
      <OfflineProvider>
        <PingProvider>
          <GameProvider>
            <Router>
              <Routes>
                {/* Public Routes */}
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/activate/:token" element={<ActivationPage />} />
              
                {/* Protected Routes */}
                <Route 
                  path="/profile" 
                  element={
                    <ProtectedRoute>
                      <ProfilePage />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/games-history" 
                  element={
                    <ProtectedRoute>
                      <GamesHistoryPage />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/game-details/:gameId" 
                  element={
                    <ProtectedRoute>
                      <GameDetailsPage />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/create-game" 
                  element={
                    <ProtectedRoute>
                      <CreateGamePage />
                    </ProtectedRoute>
                  } 
                />
                <Route 
                  path="/play/:gameId" 
                  element={
                    <ProtectedRoute>
                      <GamePage />
                    </ProtectedRoute>
                  } 
                />
                
                {/* Fallback Route */}
                <Route path="*" element={<Navigate to="/" replace />} />
              </Routes>
              
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
              />
            </Router>
          </GameProvider>
        </PingProvider>
      </OfflineProvider>
    </AuthProvider>
  );
}

export default App;
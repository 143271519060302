import React from 'react';
import { useGame } from '../../contexts/GameContext';
import { useNavigate } from 'react-router-dom';

const GameHeader = () => {
  const { 
    gameName, 
    teams, 
    currentTeamIndex, 
    switchTeam, 
    endGame, 
    returnToBoard 
  } = useGame();
  
  const navigate = useNavigate();

  const handleEndGame = async () => {
    const result = await endGame();
    if (result) {
      // Show game results and then navigate to home
      navigate('/');
    }
  };

  return (
    <header className="flex-shrink-0 bg-gradient-to-r from-teal-400 to-blue-500 text-white shadow-md p-1">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex space-x-2">
          <span className="font-bold text-sm sm:text-base md:text-lg lg:text-xl xl:text-lg 2xl:text-xl 4xl:text-2xl landscape:sm:text-sm landscape:md:text-base landscape:xl:text-lg">
            {gameName}
          </span>
        </div>
        <div className="flex space-x-2 text-white border border-solid px-1.5">
          <span>دور : </span>
          <span 
            onClick={switchTeam} 
            className="cursor-pointer"
          >
            <span className="text-sm font-bold sm:text-base md:text-lg lg:text-xl xl:text-lg 2xl:text-xl 4xl:text-2xl landscape:sm:text-sm landscape:md:text-base landscape:xl:text-lg">
              {teams[currentTeamIndex].name}
            </span>
          </span>
        </div>
        <div className="flex flex-row-reverse space-x-4 space-x-reverse">
          <button 
            onClick={handleEndGame} 
            className="text-cyan-700 font-bold mx-3 px-3 py-1 rounded text-sm sm:text-base md:text-lg lg:text-xl xl:text-lg 2xl:text-xl 4xl:text-2xl landscape:sm:text-sm landscape:md:text-base landscape:xl:text-lg"
          >
            <i className="fa-solid fa-arrow-right-from-bracket"></i> إنهاء اللعبة
          </button>
          <button 
            onClick={returnToBoard} 
            className="mx-3 font-bold text-cyan-700 px-3 py-1 rounded text-sm sm:text-base md:text-lg lg:text-xl xl:text-lg 2xl:text-xl 4xl:text-2xl landscape:sm:text-sm landscape:md:text-base landscape:xl:text-lg"
          >
            <i className="fa-solid fa-grid-dividers"></i> الرجوع للوحة
          </button>
        </div>
      </div>
    </header>
  );
};

export default GameHeader;
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import apiService from '../api/apiService';
import offlineStorage, { 
  setOfflineMode, 
  isOfflineMode, 
  getPendingActions, 
  removeQueuedAction,
  updateLastSync
} from '../utils/offlineStorage';
import { useAuth } from './AuthContext';

// Create the context
export const OfflineContext = createContext();

// Provider component
export const OfflineProvider = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [isInOfflineMode, setIsInOfflineMode] = useState(isOfflineMode());
  const [pendingActions, setPendingActions] = useState([]);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncProgress, setSyncProgress] = useState(0);

  // Update online status
  const handleOnlineStatusChange = useCallback(() => {
    const newOnlineStatus = navigator.onLine;
    setIsOnline(newOnlineStatus);
    
    if (newOnlineStatus && !isInOfflineMode) {
      toast.info('تم استعادة الاتصال بالإنترنت');
      // Attempt to sync when coming back online
      syncPendingActions();
    } else if (!newOnlineStatus) {
      toast.warning('أنت الآن في وضع عدم الاتصال');
      // Automatically enter offline mode when network is lost
      toggleOfflineMode(true);
    }
  }, [isInOfflineMode]);

  // Toggle offline mode
  const toggleOfflineMode = useCallback((value) => {
    const newOfflineMode = value === undefined ? !isInOfflineMode : value;
    setIsInOfflineMode(newOfflineMode);
    setOfflineMode(newOfflineMode);
    
    if (newOfflineMode) {
      toast.info('تم تفعيل وضع عدم الاتصال');
    } else if (isOnline) {
      toast.info('تم تعطيل وضع عدم الاتصال');
      // Try to sync when exiting offline mode while online
      syncPendingActions();
    } else {
      toast.warning('لا يمكن مزامنة البيانات. تحقق من اتصالك بالإنترنت.');
    }
  }, [isInOfflineMode, isOnline]);

  // Load pending actions from storage
  const loadPendingActions = useCallback(() => {
    const actions = getPendingActions();
    setPendingActions(actions);
    return actions;
  }, []);

  // Execute a pending action
  const executePendingAction = useCallback(async (action) => {
    try {
      // Call the API using the generic request method
      await apiService.request(
        action.action,
        action.method,
        action.data
      );
      
      // Remove action from storage
      removeQueuedAction(action.id);
      return { success: true, action };
    } catch (error) {
      console.error(`Failed to execute action ${action.action}:`, error);
      return { success: false, action, error };
    }
  }, []);

  // Sync all pending actions
  const syncPendingActions = useCallback(async () => {
    if (!isOnline || isSyncing) return;
    
    const actions = loadPendingActions();
    if (actions.length === 0) return;
    
    setIsSyncing(true);
    setSyncProgress(0);
    
    toast.info(`جاري مزامنة ${actions.length} إجراءات معلقة...`);
    
    let successCount = 0;
    const results = [];
    
    for (let i = 0; i < actions.length; i++) {
      const result = await executePendingAction(actions[i]);
      results.push(result);
      
      if (result.success) {
        successCount++;
      }
      
      // Update progress
      setSyncProgress(Math.floor(((i + 1) / actions.length) * 100));
    }
    
    // Refresh pending actions
    loadPendingActions();
    
    // Record sync time
    updateLastSync();
    
    // Show result
    if (successCount === actions.length) {
      toast.success('تمت المزامنة بنجاح');
    } else {
      toast.warning(`تمت مزامنة ${successCount} من ${actions.length} إجراءات`);
    }
    
    setIsSyncing(false);
    setSyncProgress(0);
    
    return results;
  }, [isOnline, isSyncing, loadPendingActions, executePendingAction]);

  // Listen for online/offline events
  useEffect(() => {
    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);
    
    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, [handleOnlineStatusChange]);

  // Load pending actions on init and when login state changes
  useEffect(() => {
    if (isLoggedIn) {
      loadPendingActions();
    }
  }, [isLoggedIn, loadPendingActions]);

  // Create context value
  const contextValue = {
    isOnline,
    isOfflineMode: isInOfflineMode,
    toggleOfflineMode,
    pendingActions,
    loadPendingActions,
    syncPendingActions,
    isSyncing,
    syncProgress
  };

  return (
    <OfflineContext.Provider value={contextValue}>
      {children}
    </OfflineContext.Provider>
  );
};

// Custom hook to use the offline context
export const useOffline = () => {
  const context = useContext(OfflineContext);
  if (!context) {
    throw new Error('useOffline must be used within an OfflineProvider');
  }
  return context;
};

export default OfflineContext;
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../api/apiService';
import { toast } from 'react-toastify';
import Loader from '../components/common/Loader';

const ActivationPage = () => {
  const { token } = useParams();
  const { checkAuth } = useAuth();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(true);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  
  useEffect(() => {
    if (!token) {
      setError('رمز التفعيل غير صالح');
      setLoading(false);
      return;
    }
    
    activateAccount();
  }, [token]);
  
  const activateAccount = async () => {
    try {
      setLoading(true);
      
      const response = await apiService.request('activate_account', 'POST', {
        token
      });
      
      if (response.data.success) {
        setSuccess(true);
        toast.success('تم تفعيل الحساب بنجاح');
        
        // Update auth state
        await checkAuth();
        
        // Redirect after 3 seconds
        setTimeout(() => {
          navigate('/');
        }, 3000);
      } else {
        setError(response.data.message || 'فشل في تفعيل الحساب');
      }
    } catch (error) {
      console.error('Error activating account:', error);
      setError(error.response?.data?.message || 'حدث خطأ أثناء تفعيل الحساب');
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <header className="bg-gradient-to-r from-teal-400 to-blue-500 text-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold">
              فكر وإربح
            </Link>
          </div>
        </div>
      </header>
      
      {/* Main Content */}
      <main className="flex-grow flex items-center justify-center py-12">
        <div className="w-full max-w-md px-4">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden p-6 text-center">
            {loading ? (
              <div className="py-10">
                <div className="loader mx-auto"></div>
                <p className="mt-4 text-lg">جاري تفعيل الحساب...</p>
              </div>
            ) : success ? (
              <>
                <div className="w-16 h-16 bg-green-100 rounded-full mx-auto flex items-center justify-center mb-4">
                  <i className="fas fa-check text-green-500 text-3xl"></i>
                </div>
                <h2 className="text-2xl font-bold text-green-600 mb-2">تم تفعيل الحساب بنجاح!</h2>
                <p className="text-gray-600 mb-6">
                  تم تفعيل حسابك بنجاح. يمكنك الآن الاستمتاع بكافة ميزات التطبيق.
                </p>
                <p className="text-gray-500 text-sm mb-4">
                  سيتم تحويلك للصفحة الرئيسية خلال ثواني...
                </p>
                <Link
                  to="/"
                  className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                >
                  الذهاب للصفحة الرئيسية
                </Link>
              </>
            ) : (
              <>
                <div className="w-16 h-16 bg-red-100 rounded-full mx-auto flex items-center justify-center mb-4">
                  <i className="fas fa-times text-red-500 text-3xl"></i>
                </div>
                <h2 className="text-2xl font-bold text-red-600 mb-2">فشل في تفعيل الحساب</h2>
                <p className="text-gray-600 mb-6">
                  {error || 'حدث خطأ أثناء محاولة تفعيل حسابك. الرجاء المحاولة مرة أخرى.'}
                </p>
                <div className="flex flex-col sm:flex-row gap-3 justify-center">
                  <Link
                    to="/"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  >
                    الرجوع للصفحة الرئيسية
                  </Link>
                  <Link
                    to="/profile"
                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition"
                  >
                    الملف الشخصي
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm text-gray-400">
            &copy; 2023 فكر وإربح. جميع الحقوق محفوظة.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default ActivationPage;
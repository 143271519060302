import React, { useState, useRef, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';

const VerificationForm = ({ onSuccess }) => {
  const { verificationData, verifyPhone, resendVerificationCode } = useAuth();
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  // Create refs for each input
  const inputRefs = React.useMemo(() => Array(6).fill(0).map(() => React.createRef()), []);
  
  // Focus first input on mount
  useEffect(() => {
    if (inputRefs[0]?.current) {
      inputRefs[0].current.focus();
    }
  }, []);
  
  const handleInputChange = (e, index) => {
    const value = e.target.value;
    
    // Ensure input is a number
    if (!/^\d*$/.test(value)) return;
    
    // Handle paste event
    if (e.nativeEvent.inputType === 'insertFromPaste') {
      const pastedText = value.replace(/\D/g, '').slice(0, 6);
      const digits = pastedText.split('');
      
      const newCode = [...code];
      digits.forEach((digit, i) => {
        if (i < 6) newCode[i] = digit;
      });
      
      setCode(newCode);
      
      // Focus last input or first empty input
      const lastFilledIndex = newCode.findIndex(digit => !digit);
      const focusIndex = lastFilledIndex === -1 ? 5 : lastFilledIndex;
      inputRefs[focusIndex]?.current?.focus();
      
      return;
    }
    
    // Update the code array
    const newCode = [...code];
    newCode[index] = value.substring(0, 1);
    setCode(newCode);
    
    // Clear error when typing
    if (error) setError('');
    
    // Move to next input if value entered
    if (value && index < 5) {
      inputRefs[index + 1]?.current?.focus();
    }
    
    // Check if code is complete
    if (newCode.every(digit => digit) && newCode.length === 6) {
      handleSubmit(newCode.join(''));
    }
  };
  
  const handleKeyDown = (e, index) => {
    // Move to previous input on backspace if current input is empty
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputRefs[index - 1]?.current?.focus();
      
      // Clear previous input
      const newCode = [...code];
      newCode[index - 1] = '';
      setCode(newCode);
    }
  };
  
  const handleSubmit = async (fullCode) => {
    if (loading) return;
    
    const submittedCode = fullCode || code.join('');
    if (submittedCode.length !== 6) {
      setError('يرجى إدخال الرمز كاملاً');
      return;
    }
    
    setLoading(true);
    setError('');
    
    try {
      const result = await verifyPhone(submittedCode);
      
      if (result.success) {
        if (onSuccess) onSuccess();
      } else {
        setError(result.error || 'فشل التحقق. يرجى المحاولة مرة أخرى.');
        setCode(['', '', '', '', '', '']);
        
        // Focus first input
        inputRefs[0]?.current?.focus();
      }
    } catch (error) {
      setError('حدث خطأ أثناء التحقق');
      console.error(error);
      
      // Clear code on error
      setCode(['', '', '', '', '', '']);
      
      // Focus first input
      inputRefs[0]?.current?.focus();
    } finally {
      setLoading(false);
    }
  };
  
  const handleResendCode = async () => {
    if (verificationData.resendCountdown > 0) return;
    
    try {
      await resendVerificationCode();
    } catch (error) {
      setError('فشل في إعادة إرسال الرمز');
      console.error(error);
    }
  };
  
  return (
    <div className="p-6">
      <h3 className="text-2xl font-bold text-center mb-6">تفعيل الحساب</h3>
      
      <div className="text-center mb-6">
        <p className="text-gray-600">أدخل رمز التحقق المرسل إلى</p>
        <p className="font-bold">{verificationData.phone}</p>
      </div>

      <form onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}>
        <div className="mb-6">
          <div className="flex justify-between gap-2 dir-ltr">
            {code.map((digit, index) => (
              <input
                key={index}
                ref={inputRefs[index]}
                type="text"
                value={digit}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                className="w-12 h-12 text-center border rounded-lg text-2xl focus:outline-none focus:border-blue-500"
                maxLength={1}
                pattern="[0-9]"
                inputMode="numeric"
              />
            ))}
          </div>
          {error && (
            <p className="text-red-500 text-sm mt-2 text-center">{error}</p>
          )}
        </div>

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition"
          disabled={loading || !code.every(d => d)}
        >
          {loading ? (
            <span className="flex items-center justify-center gap-2">
              <i className="fas fa-spinner fa-spin"></i>
              جاري التحقق...
            </span>
          ) : (
            'تحقق'
          )}
        </button>

        <div className="mt-4 text-center">
          <button
            type="button"
            onClick={handleResendCode}
            disabled={verificationData.resendCountdown > 0}
            className="text-blue-500 hover:text-blue-600 disabled:opacity-50"
          >
            {verificationData.resendCountdown === 0 ? (
              'إعادة إرسال الرمز'
            ) : (
              <span>
                إعادة الإرسال بعد ({verificationData.resendCountdown})
              </span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default VerificationForm;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import LoginForm from '../components/auth/LoginForm';
import RegisterForm from '../components/auth/RegisterForm';
import ForgotPasswordForm from '../components/auth/ForgotPasswordForm';
import VerificationForm from '../components/auth/VerificationForm';

const LoginPage = () => {
  const { isLoggedIn, verificationData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  
  const [activeForm, setActiveForm] = useState('login');
  
  // Redirect if already logged in
  useEffect(() => {
    if (isLoggedIn && !verificationData.showModal) {
      // Redirect to where they were trying to go or to the home page
      const from = location.state?.from?.pathname || '/';
      navigate(from, { replace: true });
    }
  }, [isLoggedIn, verificationData.showModal, navigate, location]);
  
  // Show verification form if needed
  useEffect(() => {
    if (verificationData.showModal) {
      setActiveForm('verification');
    }
  }, [verificationData.showModal]);
  
  const handleLoginSuccess = () => {
    // Verification will be handled by AuthContext if needed
  };
  
  const handleRegisterSuccess = () => {
    // Verification will be handled by AuthContext
    setActiveForm('verification');
  };
  
  const handleVerificationSuccess = () => {
    // Navigate to home or redirect URL after successful verification
    const from = location.state?.from?.pathname || '/';
    navigate(from, { replace: true });
  };
  
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <header className="bg-gradient-to-r from-teal-400 to-blue-500 text-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold">
              فكر وإربح
            </Link>
          </div>
        </div>
      </header>
      
      {/* Main Content */}
      <main className="flex-grow flex items-center justify-center py-12">
        <div className="w-full max-w-md px-4">
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            {activeForm === 'login' && (
              <div className="p-6">
                <LoginForm 
                  onRegisterClick={() => setActiveForm('register')}
                  onForgotPasswordClick={() => setActiveForm('forgot-password')}
                  onSuccess={handleLoginSuccess}
                />
              </div>
            )}
            
            {activeForm === 'register' && (
              <div className="p-6">
                <RegisterForm 
                  onLoginClick={() => setActiveForm('login')}
                  onSuccess={handleRegisterSuccess}
                />
              </div>
            )}
            
            {activeForm === 'forgot-password' && (
              <div className="p-6">
                <ForgotPasswordForm 
                  onBackToLogin={() => setActiveForm('login')}
                />
              </div>
            )}
            
            {activeForm === 'verification' && (
              <div className="p-6">
                <VerificationForm 
                  onSuccess={handleVerificationSuccess}
                />
              </div>
            )}
          </div>
          
          {/* Back to home link */}
          <div className="text-center mt-6">
            <Link to="/" className="text-blue-500 hover:text-blue-600">
              العودة للصفحة الرئيسية
            </Link>
          </div>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm text-gray-400">
            &copy; 2023 فكر وإربح. جميع الحقوق محفوظة.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default LoginPage;
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';

const LoginForm = ({ onRegisterClick, onForgotPasswordClick, onSuccess }) => {
  const { login } = useAuth();
  
  const [formData, setFormData] = useState({
    login: '',
    password: '',
    remember: false
  });
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [generalError, setGeneralError] = useState('');
  
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };
  
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.login.trim()) {
      newErrors.login = 'الرجاء إدخال البريد الإلكتروني أو رقم الهاتف';
    }
    
    if (!formData.password) {
      newErrors.password = 'الرجاء إدخال كلمة المرور';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    setLoading(true);
    setGeneralError('');
    
    try {
      const result = await login(formData);
      
      if (result.needsVerification) {
        // Verification will be handled by AuthContext
        if (onSuccess) onSuccess();
      } else if (result.success) {
        if (onSuccess) onSuccess();
      } else {
        setGeneralError(result.error || 'فشل تسجيل الدخول. الرجاء المحاولة مرة أخرى.');
      }
    } catch (error) {
      setGeneralError('حدث خطأ أثناء محاولة تسجيل الدخول');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="w-full">
      <h3 className="text-2xl font-bold text-center mb-6">
        تسجيل الدخول
      </h3>

      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            البريد الإلكتروني أو رقم الهاتف
          </label>
          <input 
            type="text" 
            name="login"
            value={formData.login}
            onChange={handleChange}
            className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
              errors.login ? 'border-red-500' : ''
            }`}
            required
          />
          {errors.login && (
            <p className="text-red-500 text-sm mt-1">{errors.login}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            كلمة المرور
          </label>
          <div className="relative">
            <input 
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={formData.password}
              onChange={handleChange}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                errors.password ? 'border-red-500' : ''
              }`}
              required
            />
            <button
              type="button" 
              onClick={() => setShowPassword(!showPassword)}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
            >
              <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
            </button>
          </div>
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">{errors.password}</p>
          )}
        </div>

        <div className="flex items-center justify-between mb-4">
          <label className="flex items-center">
            <input 
              type="checkbox" 
              name="remember"
              checked={formData.remember}
              onChange={handleChange}
              className="form-checkbox text-blue-500"
            />
            <span className="mr-2 text-sm text-gray-600">تذكرني</span>
          </label>
          <button
            type="button"
            onClick={onForgotPasswordClick}
            className="text-sm text-blue-500 hover:text-blue-600"
          >
            نسيت كلمة المرور؟
          </button>
        </div>

        {generalError && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
            <span className="block sm:inline">{generalError}</span>
          </div>
        )}

        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
          disabled={loading}
        >
          {loading ? (
            <span className="flex items-center justify-center gap-2">
              <i className="fas fa-spinner fa-spin"></i>
              جاري التحميل...
            </span>
          ) : (
            'تسجيل الدخول'
          )}
        </button>

        <div className="mt-4 text-center">
          <button
            type="button"
            onClick={onRegisterClick}
            className="text-blue-500 hover:text-blue-600"
          >
            ليس لديك حساب؟ سجل الآن
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
import React from 'react';
import { useGame } from '../../contexts/GameContext';

const TeamSelection = () => {
  const { teams, awardPoints, noOneAnswered } = useGame();

  return (
    <div className="flex justify-center items-center h-full bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg p-8 w-full max-w-md">
        <h2 className="text-2xl font-bold mb-2 text-center text-blue-600">
          <i className="fa-solid fa-comments-question-check"></i> من أجاب صح؟
        </h2>
        <div className="space-y-6">
          <button 
            onClick={() => awardPoints(0)} 
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-all transform hover:scale-105 flex items-center justify-center space-x-2 space-x-reverse"
          >
            <i className="fas fa-users text-2xl"></i>
            <span className="text-xl">{teams[0].name}</span>
          </button>
          
          <button 
            onClick={() => awardPoints(1)} 
            className="w-full bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-all transform hover:scale-105 flex items-center justify-center space-x-2 space-x-reverse"
          >
            <i className="fas fa-users text-2xl"></i>
            <span className="text-xl">{teams[1].name}</span>
          </button>
          
          <button 
            onClick={noOneAnswered} 
            className="w-full bg-gray-400 text-white px-4 py-2 rounded-lg hover:bg-gray-500 transition-all transform hover:scale-105 flex items-center justify-center space-x-2 space-x-reverse"
          >
            <i className="fa-solid fa-xmark-to-slot text-2xl"></i>
            <span className="text-xl">لا أحد</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TeamSelection;
/**
 * Utility for managing offline data storage and synchronization
 */

// Storage keys
const STORAGE_KEYS = {
  PENDING_ACTIONS: 'trivia_pending_actions',
  CACHED_GAMES: 'trivia_cached_games',
  CACHED_CATEGORIES: 'trivia_cached_categories',
  CACHED_USER: 'trivia_cached_user',
  OFFLINE_MODE: 'trivia_offline_mode',
  LAST_SYNC: 'trivia_last_sync'
};

/**
 * Save data to local storage
 * @param {string} key - Storage key
 * @param {any} data - Data to store
 */
export const saveToStorage = (key, data) => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  } catch (error) {
    console.error('Error saving to localStorage:', error);
    return false;
  }
};

/**
 * Get data from local storage
 * @param {string} key - Storage key
 * @param {any} defaultValue - Default value if key doesn't exist
 * @returns {any} - Stored data or default value
 */
export const getFromStorage = (key, defaultValue = null) => {
  try {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : defaultValue;
  } catch (error) {
    console.error('Error retrieving from localStorage:', error);
    return defaultValue;
  }
};

/**
 * Remove data from local storage
 * @param {string} key - Storage key
 */
export const removeFromStorage = (key) => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error('Error removing from localStorage:', error);
    return false;
  }
};

/**
 * Queue an action to be performed when online
 * @param {string} action - API action name
 * @param {string} method - HTTP method
 * @param {object} data - Action data
 */
export const queueAction = (action, method, data) => {
  const pendingActions = getFromStorage(STORAGE_KEYS.PENDING_ACTIONS, []);
  
  // Add timestamp to track when the action was queued
  const newAction = {
    id: Date.now() + Math.random().toString(36).substring(2, 9),
    action,
    method,
    data,
    timestamp: Date.now()
  };
  
  pendingActions.push(newAction);
  saveToStorage(STORAGE_KEYS.PENDING_ACTIONS, pendingActions);
  
  return newAction.id;
};

/**
 * Remove a queued action
 * @param {string} actionId - ID of the action to remove
 */
export const removeQueuedAction = (actionId) => {
  const pendingActions = getFromStorage(STORAGE_KEYS.PENDING_ACTIONS, []);
  const filteredActions = pendingActions.filter(action => action.id !== actionId);
  saveToStorage(STORAGE_KEYS.PENDING_ACTIONS, filteredActions);
};

/**
 * Get all pending actions
 * @returns {Array} - List of pending actions
 */
export const getPendingActions = () => {
  return getFromStorage(STORAGE_KEYS.PENDING_ACTIONS, []);
};

/**
 * Cache game data for offline use
 * @param {string} gameId - Game ID
 * @param {object} gameData - Game data to cache
 */
export const cacheGameData = (gameId, gameData) => {
  const cachedGames = getFromStorage(STORAGE_KEYS.CACHED_GAMES, {});
  cachedGames[gameId] = {
    data: gameData,
    timestamp: Date.now()
  };
  saveToStorage(STORAGE_KEYS.CACHED_GAMES, cachedGames);
};

/**
 * Get cached game data
 * @param {string} gameId - Game ID
 * @returns {object|null} - Cached game data or null
 */
export const getCachedGameData = (gameId) => {
  const cachedGames = getFromStorage(STORAGE_KEYS.CACHED_GAMES, {});
  return cachedGames[gameId]?.data || null;
};

/**
 * Cache categories for offline use
 * @param {Array} categories - Categories to cache
 */
export const cacheCategories = (categories) => {
  saveToStorage(STORAGE_KEYS.CACHED_CATEGORIES, {
    data: categories,
    timestamp: Date.now()
  });
};

/**
 * Get cached categories
 * @returns {Array|null} - Cached categories or null
 */
export const getCachedCategories = () => {
  const cached = getFromStorage(STORAGE_KEYS.CACHED_CATEGORIES, null);
  return cached?.data || null;
};

/**
 * Cache user data for offline use
 * @param {object} userData - User data to cache
 */
export const cacheUserData = (userData) => {
  saveToStorage(STORAGE_KEYS.CACHED_USER, {
    data: userData,
    timestamp: Date.now()
  });
};

/**
 * Get cached user data
 * @returns {object|null} - Cached user data or null
 */
export const getCachedUserData = () => {
  const cached = getFromStorage(STORAGE_KEYS.CACHED_USER, null);
  return cached?.data || null;
};

/**
 * Set offline mode status
 * @param {boolean} isOffline - Whether app is in offline mode
 */
export const setOfflineMode = (isOffline) => {
  saveToStorage(STORAGE_KEYS.OFFLINE_MODE, isOffline);
};

/**
 * Check if app is in offline mode
 * @returns {boolean} - Whether app is in offline mode
 */
export const isOfflineMode = () => {
  return getFromStorage(STORAGE_KEYS.OFFLINE_MODE, false);
};

/**
 * Update last sync timestamp
 */
export const updateLastSync = () => {
  saveToStorage(STORAGE_KEYS.LAST_SYNC, Date.now());
};

/**
 * Get last sync timestamp
 * @returns {number|null} - Timestamp of last sync
 */
export const getLastSync = () => {
  return getFromStorage(STORAGE_KEYS.LAST_SYNC, null);
};

// Export storage keys for direct access
export { STORAGE_KEYS };

export default {
  saveToStorage,
  getFromStorage,
  removeFromStorage,
  queueAction,
  removeQueuedAction,
  getPendingActions,
  cacheGameData,
  getCachedGameData,
  cacheCategories,
  getCachedCategories,
  cacheUserData,
  getCachedUserData,
  setOfflineMode,
  isOfflineMode,
  updateLastSync,
  getLastSync,
  STORAGE_KEYS
};
import React from 'react';
import { useGame } from '../../contexts/GameContext';
import MediaDisplay from './MediaDisplay';

const AnswerDisplay = () => {
  const { 
    currentQuestion, 
    backToQuestion, 
    selectTeam
  } = useGame();

  if (!currentQuestion) return null;

  return (
    <div className="flex justify-center items-center h-full">
      <div className="bg-white rounded-lg shadow-md p-4 w-full max-w-4xl h-[70vh] flex flex-col relative">
        <p className="text-lg mb-2 text-center px-4">
          {currentQuestion.correct_answer_text}
        </p>
        <div className="flex-grow flex justify-center items-center">
          {currentQuestion.correct_answer_file && (
            <MediaDisplay 
              file={currentQuestion.correct_answer_file}
              type="answer"
            />
          )}
        </div>
        <div className="absolute bottom-2 left-2">
          <button 
            onClick={backToQuestion} 
            className="bg-blue-500 text-white px-3 py-1 rounded text-sm sm:text-base md:text-lg lg:text-xl xl:text-lg 2xl:text-xl 4xl:text-2xl landscape:sm:text-sm landscape:md:text-base landscape:xl:text-lg hover:bg-blue-600 transition"
          >
            <i className="fa-solid fa-right"></i> الرجوع للسؤال
          </button>
        </div>
        <div className="absolute bottom-2 right-2">
          <button 
            onClick={selectTeam} 
            className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-600 transition text-sm sm:text-base md:text-lg lg:text-xl xl:text-lg 2xl:text-xl 4xl:text-2xl landscape:sm:text-sm landscape:md:text-base landscape:xl:text-lg"
          >
            <i className="fa-solid fa-circle-check"></i> منو جاوب؟
          </button>
        </div>
      </div>
    </div>
  );
};

export default AnswerDisplay;
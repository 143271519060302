import React, { useState } from 'react';
import apiService from '../../api/apiService';
import { toast } from 'react-toastify';

const ForgotPasswordForm = ({ onBackToLogin }) => {
  const [step, setStep] = useState(1); // 1: Enter Email, 2: Enter Code, 3: New Password
  const [email, setEmail] = useState('');
  const [code, setCode] = useState(['', '', '', '', '', '']);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [userId, setUserId] = useState(null);
  const [resendCountdown, setResendCountdown] = useState(0);
  
  const validateEmail = () => {
    if (!email.trim()) {
      setError('الرجاء إدخال البريد الإلكتروني');
      return false;
    }
    
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError('البريد الإلكتروني غير صحيح');
      return false;
    }
    
    setError('');
    return true;
  };
  
  const validateCode = () => {
    if (code.join('').length !== 6) {
      setError('الرجاء إدخال رمز التحقق كاملاً');
      return false;
    }
    
    setError('');
    return true;
  };
  
  const validatePassword = () => {
    if (!newPassword) {
      setError('الرجاء إدخال كلمة المرور الجديدة');
      return false;
    }
    
    if (newPassword.length < 8) {
      setError('كلمة المرور يجب أن تكون 8 أحرف على الأقل');
      return false;
    }
    
    if (newPassword !== confirmPassword) {
      setError('كلمة المرور غير متطابقة');
      return false;
    }
    
    setError('');
    return true;
  };
  
  const handleRequestCode = async (e) => {
    e.preventDefault();
    
    if (!validateEmail()) return;
    
    setLoading(true);
    setError('');
    
    try {
      const response = await apiService.request('request_password_reset', 'POST', { email });
      
      if (response.data.success) {
        setUserId(response.data.data.user_id);
        setStep(2);
        startResendCountdown();
        toast.success('تم إرسال رمز التحقق إلى بريدك الإلكتروني');
      } else {
        setError(response.data.message || 'فشل في إرسال رمز التحقق');
      }
    } catch (error) {
      console.error('Error requesting code:', error);
      setError(error.response?.data?.message || 'حدث خطأ في النظام. يرجى المحاولة مرة أخرى.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleVerifyCode = async (e) => {
    e.preventDefault();
    
    if (!validateCode()) return;
    
    setLoading(true);
    setError('');
    
    try {
      const response = await apiService.request('verify_reset_code', 'POST', {
        user_id: userId,
        code: code.join('')
      });
      
      if (response.data.success) {
        setStep(3);
        toast.success('تم التحقق من الرمز بنجاح');
      } else {
        setError(response.data.message || 'رمز التحقق غير صحيح');
        setCode(['', '', '', '', '', '']);
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setError(error.response?.data?.message || 'حدث خطأ في النظام. يرجى المحاولة مرة أخرى.');
      setCode(['', '', '', '', '', '']);
    } finally {
      setLoading(false);
    }
  };
  
  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (!validatePassword()) return;
    
    setLoading(true);
    setError('');
    
    try {
      const response = await apiService.request('reset_password', 'POST', {
        user_id: userId,
        password: newPassword,
        password_confirmation: confirmPassword
      });
      
      if (response.data.success) {
        toast.success('تم تغيير كلمة المرور بنجاح');
        onBackToLogin();
      } else {
        setError(response.data.message || 'فشل في تغيير كلمة المرور');
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(error.response?.data?.message || 'حدث خطأ في النظام. يرجى المحاولة مرة أخرى.');
    } finally {
      setLoading(false);
    }
  };
  
  const handleCodeInput = (index, value) => {
    if (!/^\d*$/.test(value)) return;
    
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    
    // Auto focus next input
    if (value && index < 5) {
      document.getElementById(`code-input-${index + 1}`)?.focus();
    }
    
    // Clear error
    if (error) setError('');
  };
  
  const handleCodeKeyDown = (index, e) => {
    // Move to previous input on backspace if current input is empty
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      document.getElementById(`code-input-${index - 1}`)?.focus();
    }
  };
  
  const handleResendCode = async () => {
    if (resendCountdown > 0) return;
    
    setLoading(true);
    setError('');
    
    try {
      const response = await apiService.request('resend_reset_code', 'POST', { user_id: userId });
      
      if (response.data.success) {
        startResendCountdown();
        toast.success('تم إعادة إرسال رمز التحقق');
      } else {
        setError(response.data.message || 'فشل في إعادة إرسال رمز التحقق');
      }
    } catch (error) {
      console.error('Error resending code:', error);
      setError(error.response?.data?.message || 'حدث خطأ في النظام. يرجى المحاولة مرة أخرى.');
    } finally {
      setLoading(false);
    }
  };
  
  const startResendCountdown = () => {
    setResendCountdown(120); // 2 minutes
    
    const timer = setInterval(() => {
      setResendCountdown(prev => {
        if (prev <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };
  
  return (
    <div className="w-full">
      <h3 className="text-2xl font-bold text-center mb-6">
        استعادة كلمة المرور
      </h3>
      
      {step === 1 && (
        <form onSubmit={handleRequestCode}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              البريد الإلكتروني
            </label>
            <input 
              type="email" 
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                if (error) setError('');
              }}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                error ? 'border-red-500' : ''
              }`}
              placeholder="example@mail.com"
              required
            />
            {error && (
              <p className="text-red-500 text-sm mt-1">{error}</p>
            )}
            <p className="text-gray-500 text-xs mt-2">
              سنرسل رمز تحقق إلى بريدك الإلكتروني لإعادة تعيين كلمة المرور
            </p>
          </div>
          
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center justify-center gap-2">
                <i className="fas fa-spinner fa-spin"></i>
                جاري الإرسال...
              </span>
            ) : (
              'إرسال رمز التحقق'
            )}
          </button>
          
          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={onBackToLogin}
              className="text-blue-500 hover:text-blue-600"
            >
              العودة لتسجيل الدخول
            </button>
          </div>
        </form>
      )}
      
      {step === 2 && (
        <form onSubmit={handleVerifyCode}>
          <div className="mb-6">
            <p className="text-center text-gray-600 mb-4">
              تم إرسال رمز التحقق إلى بريدك الإلكتروني<br />
              <span className="font-semibold">{email}</span>
            </p>
            
            <div className="flex justify-center gap-2 mb-2 dir-ltr">
              {[0, 1, 2, 3, 4, 5].map(index => (
                <input
                  key={index}
                  id={`code-input-${index}`}
                  type="text"
                  value={code[index]}
                  onChange={(e) => handleCodeInput(index, e.target.value)}
                  onKeyDown={(e) => handleCodeKeyDown(index, e)}
                  className="w-12 h-12 text-center border rounded-lg text-2xl focus:outline-none focus:border-blue-500"
                  maxLength={1}
                  pattern="[0-9]"
                  inputMode="numeric"
                  autoFocus={index === 0}
                />
              ))}
            </div>
            
            {error && (
              <p className="text-red-500 text-sm mt-1 text-center">{error}</p>
            )}
          </div>
          
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
            disabled={loading || code.some(digit => !digit)}
          >
            {loading ? (
              <span className="flex items-center justify-center gap-2">
                <i className="fas fa-spinner fa-spin"></i>
                جاري التحقق...
              </span>
            ) : (
              'تحقق من الرمز'
            )}
          </button>
          
          <div className="mt-4 text-center">
            <button
              type="button"
              onClick={handleResendCode}
              disabled={resendCountdown > 0 || loading}
              className="text-blue-500 hover:text-blue-600 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {resendCountdown > 0 ? (
                `إعادة الإرسال بعد (${resendCountdown} ثانية)`
              ) : (
                'إعادة إرسال الرمز'
              )}
            </button>
          </div>
          
          <div className="mt-2 text-center">
            <button
              type="button"
              onClick={() => setStep(1)}
              className="text-gray-500 hover:text-gray-600 text-sm"
            >
              تغيير البريد الإلكتروني
            </button>
          </div>
        </form>
      )}
      
      {step === 3 && (
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              كلمة المرور الجديدة
            </label>
            <div className="relative">
              <input 
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  if (error) setError('');
                }}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  error && !newPassword ? 'border-red-500' : ''
                }`}
                required
              />
              <button
                type="button" 
                onClick={() => setShowPassword(!showPassword)}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
              >
                <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
              </button>
            </div>
            <p className="text-gray-500 text-xs mt-2">
              يجب أن تكون كلمة المرور 8 أحرف على الأقل
            </p>
          </div>
          
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              تأكيد كلمة المرور
            </label>
            <input 
              type={showPassword ? 'text' : 'password'}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                if (error) setError('');
              }}
              className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                error && newPassword !== confirmPassword ? 'border-red-500' : ''
              }`}
              required
            />
          </div>
          
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
              <span className="block sm:inline">{error}</span>
            </div>
          )}
          
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition"
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center justify-center gap-2">
                <i className="fas fa-spinner fa-spin"></i>
                جاري التحديث...
              </span>
            ) : (
              'تغيير كلمة المرور'
            )}
          </button>
        </form>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { toast } from 'react-toastify';
import apiService from '../api/apiService';
import MainLayout from '../components/layout/MainLayout';
import Modal from '../components/common/Modal';
import LoginForm from '../components/auth/LoginForm';
import RegisterForm from '../components/auth/RegisterForm';
import VerificationForm from '../components/auth/VerificationForm';
import Loader from '../components/common/Loader';

const HomePage = () => {
  const { isLoggedIn, userData, loading: authLoading, verificationData } = useAuth();
  
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showPackagesModal, setShowPackagesModal] = useState(false);
  
  const [categories, setCategories] = useState([]);
  const [helpOptions, setHelpOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  
  useEffect(() => {
    // Show verification form if needed
    if (verificationData.showModal) {
      setShowVerificationModal(true);
    }
  }, [verificationData.showModal]);
  
  // Fetch categories and help options
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [categoriesResponse, helpOptionsResponse] = await Promise.all([
          apiService.getCategories(),
          apiService.getHelpOptions()
        ]);
        
        if (categoriesResponse.data.success) {
          setCategories(categoriesResponse.data.data);
        }
        
        if (helpOptionsResponse.data.success) {
          setHelpOptions(helpOptionsResponse.data.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Failed to load categories and help options');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);
  
  // Toggle category selection
  const toggleCategory = (categoryId) => {
    if (!isLoggedIn) {
      setShowLoginModal(true);
      return;
    }
    
    setSelectedCategories(prev => {
      const index = prev.indexOf(categoryId);
      if (index === -1) {
        if (prev.length < 6) {
          return [...prev, categoryId];
        } else {
          toast.warning('Cannot select more than 6 categories');
          return prev;
        }
      } else {
        return prev.filter(id => id !== categoryId);
      }
    });
  };
  
  // Static testimonials data
  const testimonials = [
    {
      id: 1,
      name: 'محمد العنزي',
      text: 'تطبيق رائع ومفيد! نستخدمه في جلسات العائلة والأصدقاء.',
      rating: 5
    },
    {
      id: 2,
      name: 'فاطمة الكندري',
      text: 'أسئلة متنوعة ومثيرة للاهتمام. أنصح به بشدة.',
      rating: 5
    },
    {
      id: 3,
      name: 'عبدالله السالم',
      text: 'المساعدات تضيف إثارة للعبة. تجربة ممتعة!',
      rating: 5
    }
  ];
  
  // Static FAQs data
  const faqs = [
    {
      question: 'كيف يمكنني البدء باللعب؟',
      answer: 'قم بتسجيل حساب جديد أو تسجيل الدخول، ثم اختر التصنيفات المناسبة وأنشئ لعبة جديدة.'
    },
    {
      question: 'ما هو نظام النقاط؟',
      answer: 'يعتمد نظام النقاط على صعوبة الأسئلة: سهل (50 نقطة)، متوسط (150 نقطة)، صعب (300 نقطة).'
    },
    {
      question: 'كيف تعمل المساعدات؟',
      answer: 'يمكن لكل فريق استخدام المساعدات المتاحة مقابل عدد معين من النقاط. تختلف المساعدات في تأثيرها ونقاطها.'
    },
    {
      question: 'كم عدد الألعاب المتاحة؟',
      answer: 'يعتمد عدد الألعاب على نوع حسابك. الحساب المجاني يتيح 3 ألعاب يومياً، ويمكنك الترقية للحصول على المزيد.'
    },
    {
      question: 'هل يمكنني اللعب بدون تسجيل؟',
      answer: 'لا، يجب تسجيل حساب للاستفادة من جميع مميزات اللعبة وحفظ تقدمك.'
    }
  ];
  
  // Packages data
  const packages = [
    {
      id: 'basic',
      name: 'الباقة الأساسية',
      price: 5,
      currency: 'د.ك',
      period: 'شهرياً',
      features: [
        '20 لعبة شهرياً',
        'جميع التصنيفات',
        'المساعدات الأساسية'
      ]
    },
    {
      id: 'premium',
      name: 'الباقة المميزة',
      price: 10,
      currency: 'د.ك',
      period: 'شهرياً',
      features: [
        'ألعاب غير محدودة',
        'جميع التصنيفات',
        'جميع المساعدات',
        'دعم فني متميز'
      ],
      featured: true
    },
    {
      id: 'pro',
      name: 'الباقة الاحترافية',
      price: 15,
      currency: 'د.ك',
      period: 'شهرياً',
      features: [
        'ألعاب غير محدودة',
        'محتوى حصري',
        'مساعدات خاصة',
        'تقارير تفصيلية',
        'دعم على مدار الساعة'
      ]
    }
  ];
  
  if (authLoading || loading) {
    return <Loader />;
  }
  
  return (
    <MainLayout>
      {/* Hero Section */}
      <div className="bg-gradient-to-r from-teal-400 to-blue-500 text-white py-16">
        <div className="container mx-auto px-4">
          <div className="text-center max-w-3xl mx-auto">
            <h1 className="text-4xl font-bold mb-4">تحدى عقلك وابتكر المتعة مع فكر وإربح</h1>
            <p className="text-xl mb-8 opacity-90">اختبر معلوماتك في مجالات متنوعة وتنافس مع أصدقائك في أجواء مليئة بالتحدي والمرح</p>
            
            {!isLoggedIn && (
              <div className="flex justify-center gap-4">
                <button 
                  onClick={() => setShowLoginModal(true)}
                  className="bg-white text-blue-600 px-8 py-3 rounded-lg hover:bg-blue-50 transition font-bold"
                >
                  ابدأ اللعب الآن
                </button>
                <button 
                  onClick={() => setShowPackagesModal(true)}
                  className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 transition font-bold"
                >
                  اشترك معنا
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8">
        {/* Quick Stats for Logged In Users */}
        {isLoggedIn && (
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-12">
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="text-3xl font-bold text-blue-500 mb-2">{userData.games_remaining}</div>
              <div className="text-gray-600">الألعاب المتبقية</div>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="text-3xl font-bold text-green-500 mb-2">{userData.games_played}</div>
              <div className="text-gray-600">الألعاب المكتملة</div>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="text-3xl font-bold text-purple-500 mb-2">{userData.total_points}</div>
              <div className="text-gray-600">مجموع النقاط</div>
            </div>
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="text-3xl font-bold text-orange-500 mb-2">
                <span>{userData.win_percentage}</span>%
              </div>
              <div className="text-gray-600">نسبة الفوز</div>
            </div>
          </div>
        )}

        {/* Phone Verification Alert */}
        {isLoggedIn && !userData.phone_verified && (
          <div className="bg-yellow-50 border-r-4 border-yellow-400 p-4 mb-8">
            <div className="flex">
              <div className="flex-shrink-0">
                <i className="fas fa-exclamation-triangle text-yellow-400"></i>
              </div>
              <div className="mr-3">
                <p className="text-sm text-yellow-700">
                  لم يتم تفعيل حسابك بعد. قم بتأكيد رقم هاتفك للحصول على جميع المميزات.
                </p>
                <button 
                  onClick={() => setShowVerificationModal(true)}
                  className="mt-2 text-sm font-medium text-yellow-700 hover:text-yellow-600"
                >
                  تفعيل الحساب <i className="fas fa-arrow-left mr-1"></i>
                </button>
              </div>
            </div>
          </div>
        )}
        
        {/* Categories Section */}
        <div className="mb-12">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">التصنيفات المتاحة</h2>
            {isLoggedIn && (
              <div className="text-sm text-gray-600">
                تم اختيار <span className="font-bold text-blue-600">{selectedCategories.length}</span> من 6
              </div>
            )}
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
            {categories.map(category => (
              <div 
                key={category.id}
                className={`category-card bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition cursor-pointer ${
                  isLoggedIn && selectedCategories.includes(category.id) ? 'ring-2 ring-blue-500' : ''
                } ${
                  isLoggedIn && selectedCategories.length >= 6 && !selectedCategories.includes(category.id) ? 'opacity-50' : ''
                }`}
                onClick={() => toggleCategory(category.id)}
              >
                <div className="relative h-40">
                  <img 
                    src={category.image} 
                    alt={category.name}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                  <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                    <h3 className="font-bold">{category.name}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Help Options Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6">المساعدات المتاحة</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {helpOptions.map(option => (
              <div 
                key={option.id}
                className="help-option-card bg-white rounded-lg shadow-md p-6"
              >
                <div className="flex items-center gap-4 mb-4">
                  <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center">
                    <i className={`${option.icon} text-2xl text-blue-500`}></i>
                  </div>
                  <h3 className="font-bold">{option.name}</h3>
                </div>
                <p className="text-gray-600 text-sm mb-4">{option.description}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Packages Section */}
        <div className="mb-12" id="packages">
          <h2 className="text-2xl font-bold mb-6">الباقات المتوفرة</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {packages.map(pkg => (
              <div 
                key={pkg.id}
                className="package-card bg-white rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-all duration-300 relative"
              >
                {pkg.featured && (
                  <div className="absolute top-0 right-0 bg-yellow-400 text-white px-4 py-1 text-sm font-bold transform translate-x-8 translate-y-4 rotate-45">
                    الأكثر شعبية
                  </div>
                )}
                <div className={`bg-gradient-to-br ${
                  pkg.id === 'basic' ? 'from-blue-500 to-blue-600' :
                  pkg.id === 'premium' ? 'from-purple-500 to-purple-600' :
                  'from-green-500 to-green-600'
                } p-6 text-white text-center`}>
                  <h3 className="text-xl font-bold mb-2">{pkg.name}</h3>
                  <div className="text-3xl font-bold mb-1">{pkg.price} {pkg.currency}</div>
                  <div className="text-sm opacity-75">{pkg.period}</div>
                </div>
                <div className="p-6">
                  <ul className="space-y-3 mb-6">
                    {pkg.features.map((feature, idx) => (
                      <li key={idx} className="flex items-center gap-2">
                        <i className="fas fa-check-circle text-green-500"></i>
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                  <button 
                    onClick={() => {
                      if (!isLoggedIn) {
                        setShowLoginModal(true);
                      } else {
                        // Handle package selection
                      }
                    }}
                    className={`w-full ${
                      pkg.id === 'basic' ? 'bg-blue-500 hover:bg-blue-600' :
                      pkg.id === 'premium' ? 'bg-purple-500 hover:bg-purple-600' :
                      'bg-green-500 hover:bg-green-600'
                    } text-white py-2 rounded-lg transition-colors`}
                  >
                    اشترك الآن
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* FAQ Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6">الأسئلة الشائعة</h2>
          
          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <details key={index} className="bg-white rounded-lg shadow-md overflow-hidden group">
                <summary className="px-6 py-4 cursor-pointer bg-gray-50 hover:bg-gray-100 transition flex items-center justify-between">
                  <span className="font-bold">{faq.question}</span>
                  <i className="fas fa-chevron-down group-open:rotate-180 transition-transform"></i>
                </summary>
                <div className="px-6 py-4">
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              </details>
            ))}
          </div>
        </div>

        {/* Testimonials Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6">آراء المستخدمين</h2>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {testimonials.map(testimonial => (
              <div key={testimonial.id} className="bg-white rounded-lg shadow-md p-6">
                <div className="flex items-center gap-4 mb-4">
                  <div className="w-12 h-12 bg-gradient-to-br from-blue-500 to-blue-600 rounded-full flex items-center justify-center text-white font-bold">
                    {testimonial.name[0]}
                  </div>
                  <div>
                    <h4 className="font-bold">{testimonial.name}</h4>
                    <div className="flex text-yellow-400">
                      {[...Array(5)].map((_, i) => (
                        <i key={i} className="fas fa-star"></i>
                      ))}
                    </div>
                  </div>
                </div>
                <p className="text-gray-600">{testimonial.text}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Contact Section */}
        <div className="mb-12">
          <h2 className="text-2xl font-bold mb-6">تواصل معنا</h2>
          
          <div className="bg-white rounded-lg shadow-md p-6">
            <form className="max-w-2xl mx-auto">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">الاسم</label>
                  <input 
                    type="text" 
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div>
                  <label className="block text-gray-700 text-sm font-bold mb-2">البريد الإلكتروني</label>
                  <input 
                    type="email" 
                    className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-2">الرسالة</label>
                <textarea 
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:border-blue-500 h-32"
                  required
                ></textarea>
              </div>
              <div className="text-left">
                <button 
                  type="submit"
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  إرسال
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Modals */}
      <Modal 
        isOpen={showLoginModal} 
        onClose={() => setShowLoginModal(false)}
        title="تسجيل الدخول"
      >
        <LoginForm 
          onRegisterClick={() => {
            setShowLoginModal(false);
            setShowRegisterModal(true);
          }}
          onForgotPasswordClick={() => {
            // Handle forgot password
          }}
          onSuccess={() => setShowLoginModal(false)}
        />
      </Modal>

      <Modal
        isOpen={showRegisterModal} 
        onClose={() => setShowRegisterModal(false)}
        title="إنشاء حساب جديد"
      >
        <RegisterForm 
          onLoginClick={() => {
            setShowRegisterModal(false);
            setShowLoginModal(true);
          }}
          onSuccess={() => setShowRegisterModal(false)}
        />
      </Modal>

      <Modal
        isOpen={showVerificationModal} 
        onClose={() => setShowVerificationModal(false)}
        title="تفعيل الحساب"
      >
        <VerificationForm 
          onSuccess={() => setShowVerificationModal(false)}
        />
      </Modal>
    </MainLayout>
  );
};

export default HomePage;
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';

const RegisterForm = ({ onLoginClick, onSuccess }) => {
  const { register } = useAuth();
  
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    full_name: '',
    username: '',
    email: '',
    phone: '',
    phone_country: '+965',
    password: '',
    password_confirmation: ''
  });
  
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const [generalError, setGeneralError] = useState('');
  
  // Registration steps
  const steps = [
    { title: 'المعلومات الأساسية', isValid: false },
    { title: 'رقم الهاتف', isValid: false },
    { title: 'كلمة المرور', isValid: false }
  ];
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };
  
  const validateStep = () => {
    const newErrors = {};
    
    // Step 1: Basic Information
    if (currentStep === 0) {
      if (!formData.full_name.trim()) {
        newErrors.full_name = 'الاسم الكامل مطلوب';
      }
      
      if (!formData.username.trim()) {
        newErrors.username = 'اسم المستخدم مطلوب';
      } else if (!/^[a-zA-Z0-9_]{3,20}$/.test(formData.username)) {
        newErrors.username = 'اسم المستخدم يجب أن يكون من 3 إلى 20 حرفاً ويحتوي على أحرف وأرقام وشرطة سفلية فقط';
      }
      
      if (!formData.email.trim()) {
        newErrors.email = 'البريد الإلكتروني مطلوب';
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
        newErrors.email = 'البريد الإلكتروني غير صحيح';
      }
    }
    
    // Step 2: Phone Number
    else if (currentStep === 1) {
      if (!formData.phone.trim()) {
        newErrors.phone = 'رقم الهاتف مطلوب';
      } else if (!/^\d{8,}$/.test(formData.phone)) {
        newErrors.phone = 'رقم الهاتف غير صحيح';
      }
    }
    
    // Step 3: Password
    else if (currentStep === 2) {
      if (!formData.password) {
        newErrors.password = 'كلمة المرور مطلوبة';
      } else if (formData.password.length < 8) {
        newErrors.password = 'كلمة المرور يجب أن تكون 8 أحرف على الأقل';
      }
      
      if (!formData.password_confirmation) {
        newErrors.password_confirmation = 'تأكيد كلمة المرور مطلوب';
      } else if (formData.password !== formData.password_confirmation) {
        newErrors.password_confirmation = 'كلمة المرور غير متطابقة';
      }
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const goToNextStep = () => {
    if (!validateStep()) return;
    
    setCurrentStep(prev => Math.min(prev + 1, steps.length - 1));
  };
  
  const goToPreviousStep = () => {
    setCurrentStep(prev => Math.max(prev - 1, 0));
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateStep()) return;
    
    // Final submission (all steps completed)
    setLoading(true);
    setGeneralError('');
    
    try {
      const result = await register({
        ...formData,
        phone: formData.phone_country + formData.phone
      });
      
      if (result.success) {
        if (result.needsVerification) {
          // Verification will be handled by AuthContext
          if (onSuccess) onSuccess();
        }
      } else {
        setGeneralError(result.error || 'فشل في إنشاء الحساب. الرجاء المحاولة مرة أخرى.');
      }
    } catch (error) {
      setGeneralError('حدث خطأ أثناء محاولة إنشاء الحساب');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  
  return (
    <div className="w-full">
      <h3 className="text-2xl font-bold text-center mb-6">
        إنشاء حساب جديد
      </h3>
      
      {/* Registration Steps */}
      <div className="mb-8">
        <div className="relative">
          <div className="flex mb-2 justify-between">
            {steps.map((step, index) => (
              <div key={index} className="text-center flex-1">
                <div className={`${currentStep >= index ? 'bg-blue-500' : 'bg-gray-300'} 
                                w-10 h-10 mx-auto rounded-full text-lg text-white flex items-center justify-center`}>
                  {currentStep > index ? (
                    <i className="fas fa-check"></i>
                  ) : (
                    <span>{index + 1}</span>
                  )}
                </div>
                <div className="text-xs mt-1">{step.title}</div>
              </div>
            ))}
          </div>
          <div className="relative">
            <div className="absolute top-5 w-full">
              <div className="h-1 mx-auto bg-gray-200" style={{ width: '100%' }}>
                <div 
                  className="h-1 bg-blue-500 transition-all duration-500" 
                  style={{ width: `${(currentStep / (steps.length - 1)) * 100}%` }}>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <form onSubmit={handleSubmit}>
        {/* Step 1: Basic Information */}
        {currentStep === 0 && (
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                الاسم الكامل
              </label>
              <input 
                type="text" 
                name="full_name"
                value={formData.full_name}
                onChange={handleChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  errors.full_name ? 'border-red-500' : ''
                }`}
                required
              />
              {errors.full_name && (
                <p className="text-red-500 text-sm mt-1">{errors.full_name}</p>
              )}
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                اسم المستخدم
              </label>
              <input 
                type="text" 
                name="username"
                value={formData.username}
                onChange={handleChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  errors.username ? 'border-red-500' : ''
                }`}
                required
              />
              {errors.username && (
                <p className="text-red-500 text-sm mt-1">{errors.username}</p>
              )}
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                البريد الإلكتروني
              </label>
              <input 
                type="email" 
                name="email"
                value={formData.email}
                onChange={handleChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  errors.email ? 'border-red-500' : ''
                }`}
                required
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>
          </div>
        )}
        
        {/* Step 2: Phone Number */}
        {currentStep === 1 && (
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                رقم الهاتف
              </label>
              <div className="flex">
                <select 
                  name="phone_country"
                  value={formData.phone_country}
                  onChange={handleChange}
                  className="px-3 py-2 border rounded-r-lg focus:outline-none focus:border-blue-500"
                >
                  <option value="+965">🇰🇼 +965</option>
                  <option value="+966">🇸🇦 +966</option>
                  <option value="+971">🇦🇪 +971</option>
                  <option value="+973">🇧🇭 +973</option>
                  <option value="+974">🇶🇦 +974</option>
                </select>
                <input 
                  type="tel" 
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded-l-lg focus:outline-none focus:border-blue-500 ${
                    errors.phone ? 'border-red-500' : ''
                  }`}
                  placeholder="xxxxxxxx"
                  required
                />
              </div>
              {errors.phone && (
                <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
              )}
              <p className="text-gray-500 text-xs mt-2">
                سنرسل رمز تحقق إلى هذا الرقم. رسوم الرسائل القياسية قد تطبق.
              </p>
            </div>
          </div>
        )}
        
        {/* Step 3: Password */}
        {currentStep === 2 && (
          <div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                كلمة المرور
              </label>
              <div className="relative">
                <input 
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                    errors.password ? 'border-red-500' : ''
                  }`}
                  required
                />
                <button
                  type="button" 
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                >
                  <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                </button>
              </div>
              {errors.password && (
                <p className="text-red-500 text-sm mt-1">{errors.password}</p>
              )}
              <p className="text-gray-500 text-xs mt-2">
                يجب أن تكون كلمة المرور 8 أحرف على الأقل
              </p>
            </div>
            
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                تأكيد كلمة المرور
              </label>
              <div className="relative">
                <input 
                  type={showPasswordConfirmation ? 'text' : 'password'}
                  name="password_confirmation"
                  value={formData.password_confirmation}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                    errors.password_confirmation ? 'border-red-500' : ''
                  }`}
                  required
                />
                <button
                  type="button" 
                  onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                >
                  <i className={`fas ${showPasswordConfirmation ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                </button>
              </div>
              {errors.password_confirmation && (
                <p className="text-red-500 text-sm mt-1">{errors.password_confirmation}</p>
              )}
            </div>
            
            {generalError && (
              <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                <span className="block sm:inline">{generalError}</span>
              </div>
            )}
          </div>
        )}
        
        {/* Navigation Buttons */}
        <div className="flex justify-between mt-6">
          {currentStep > 0 && (
            <button
              type="button"
              onClick={goToPreviousStep}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded-lg hover:bg-gray-400 transition"
            >
              السابق
            </button>
          )}
          
          {currentStep < steps.length - 1 ? (
            <button
              type="button"
              onClick={goToNextStep}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition mr-auto"
            >
              التالي
            </button>
          ) : (
            <button
              type="submit"
              className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition mr-auto"
              disabled={loading}
            >
              {loading ? (
                <span className="flex items-center justify-center gap-2">
                  <i className="fas fa-spinner fa-spin"></i>
                  جاري التسجيل...
                </span>
              ) : (
                'إنشاء الحساب'
              )}
            </button>
          )}
        </div>
        
        <div className="mt-4 text-center">
          <button
            type="button"
            onClick={onLoginClick}
            className="text-blue-500 hover:text-blue-600"
          >
            لديك حساب بالفعل؟ تسجيل الدخول
          </button>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;
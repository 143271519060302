import React from 'react';
import { useGame } from '../../contexts/GameContext';
import MediaDisplay from './MediaDisplay';

const QuestionDisplay = () => {
  const { 
    currentQuestion, 
    getPointsForClass, 
    timer, 
    isTimerRunning,
    startTimer, 
    stopTimer, 
    resetTimer, 
    formatTime,
    showAnswer,
    submitComplaint
  } = useGame();

  // Parse HTML content
  const getQuestionText = () => {
    if (!currentQuestion) return '';
    
    let text = currentQuestion.question_text;
    
    if (currentQuestion.icon_number > 0) {
      const iconLabel = currentQuestion.icon_number === 1 ? ' سنه' :
                        currentQuestion.icon_number === 2 ? ' موسم' :
                        currentQuestion.icon_number === 3 ? ' رقم' : '';
      
      text += `<span class="text-red-500">${iconLabel}</span> <i class="fa-duotone fa-solid fa-arrows-up-down"></i>`;
    }
    
    return text;
  };

  const handleComplaintSubmit = () => {
    if (!currentQuestion) return;
    
    // Ask for complaint details
    const description = window.prompt('يرجى كتابة وصف للمشكلة');
    if (!description || description.trim().length < 5) {
      alert('يرجى إدخال وصف أكثر تفصيلاً');
      return;
    }
    
    submitComplaint(currentQuestion.id, description);
  };

  if (!currentQuestion) return null;

  return (
    <div className="flex justify-center items-center h-full">
      <div className="bg-white rounded-lg shadow-md p-4 w-full max-w-4xl h-[70vh] flex flex-col relative">
        <div className="absolute top-3 -translate-y-1/2 left-1/2 -translate-x-1/2 flex justify-between items-center w-full max-w-[90%]">
          <span className="text-sm sm:text-lg font-bold bg-zinc-600 text-white px-2 rounded-xl w-fit">
            النقاط: {getPointsForClass(currentQuestion.class)}
          </span>
          
          <div className="grid grid-flow-col justify-between gap-2 bg-zinc-600 rounded-full p-1 pt-2 max-w-full w-36 btn-wrapper text-white px-3 text-lg font-black">
            {!isTimerRunning && (
              <button onClick={startTimer}>
                <i className="fa-solid fa-play" aria-hidden="true"></i>
              </button>
            )}
            {isTimerRunning && (
              <button onClick={stopTimer}>
                <i className="fa-solid fa-pause"></i>
              </button>
            )}
            <span>{formatTime(timer)}</span>
            <button onClick={resetTimer}>
              <i className="fa-solid fa-rotate-right" aria-hidden="true"></i>
            </button>
          </div>
          
          <i 
            aria-hidden="true" 
            onClick={handleComplaintSubmit} 
            className="fa-solid fa-pen-field text-sm sm:text-lg font-bold bg-red-700 text-white px-1.5 rounded-xl w-fit cursor-pointer"
          ></i>
        </div>
        
        <div className="flex justify-center items-center w-full flex-col h-auto py-2">
          <h2 
            className="text-lg font-bold text-center mt-4 py-2"
            dangerouslySetInnerHTML={{ __html: getQuestionText() }}
          ></h2>
          <div className="flex-grow flex justify-center items-center flex-col">
            {currentQuestion.question_file && (
              <MediaDisplay 
                file={currentQuestion.question_file} 
                type="question" 
              />
            )}
          </div>
        </div>
        
        <div className="absolute bottom-2 left-2">
          <button 
            onClick={showAnswer} 
            className="bg-green-500 text-white px-3 py-1 rounded text-sm sm:text-base md:text-lg lg:text-xl xl:text-lg 2xl:text-xl 4xl:text-2xl landscape:sm:text-sm landscape:md:text-base landscape:xl:text-lg hover:bg-green-600 transition"
          >
            <i className="fa-solid fa-eye"></i> عرض الإجابة
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuestionDisplay;
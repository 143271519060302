import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import PingStatus from '../common/PingStatus';
import OfflineStatus from '../common/OfflineStatus';

const Navbar = () => {
  const { isLoggedIn, userData, logout } = useAuth();
  const navigate = useNavigate();
  
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  
  const handleLogout = async () => {
    await logout();
    setUserMenuOpen(false);
    navigate('/');
  };
  
  return (
    <header className="bg-gradient-to-r from-teal-400 to-blue-500 text-white shadow-md">
      <nav className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          {/* Logo */}
          <Link to="/" className="text-2xl font-bold flex items-center">
            <i className="fas fa-brain ml-2"></i>
            فكر وإربح
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-6">
            {isLoggedIn && (
              <>
                <Link 
                  to="/create-game" 
                  className="text-white hover:text-blue-100 transition"
                >
                  <i className="fas fa-plus-circle ml-1"></i>
                  لعبة جديدة
                </Link>
                <Link 
                  to="/games-history" 
                  className="text-white hover:text-blue-100 transition"
                >
                  <i className="fas fa-history ml-1"></i>
                  سجل الألعاب
                </Link>
              </>
            )}
          </div>

          {/* User Menu (Desktop) */}
          <div className="hidden md:flex items-center gap-4">
            {!isLoggedIn ? (
              <div className="space-x-2 space-x-reverse">
                <Link
                  to="/login"
                  className="bg-white text-blue-600 px-4 py-2 rounded-lg hover:bg-blue-50 transition"
                >
                  تسجيل الدخول
                </Link>
                <Link
                  to="/login"
                  state={{ activeForm: 'register' }}
                  className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                >
                  حساب جديد
                </Link>
              </div>
            ) : (
              <div className="relative">
                <button
                  onClick={() => setUserMenuOpen(!userMenuOpen)}
                  className="flex items-center gap-2 bg-white bg-opacity-20 px-4 py-2 rounded-lg hover:bg-opacity-30 transition"
                >
                  <div className="flex items-center gap-2">
                    <OfflineStatus />
                    <PingStatus />
                    <span>{userData.full_name || 'المستخدم'}</span>
                  </div>
                  <i className={`fas fa-chevron-down transition-transform ${userMenuOpen ? 'rotate-180' : ''}`}></i>
                </button>

                {/* Dropdown Menu */}
                {userMenuOpen && (
                  <div
                    className="absolute left-0 mt-2 w-48 bg-white rounded-lg shadow-lg py-1 z-10"
                    onClick={() => setUserMenuOpen(false)}
                  >
                    <Link
                      to="/profile"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      <i className="fas fa-user w-5 text-gray-400 ml-2"></i>
                      الملف الشخصي
                    </Link>
                    
                    <Link
                      to="/games-history"
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
                    >
                      <i className="fas fa-gamepad w-5 text-gray-400 ml-2"></i>
                      سجل الألعاب
                    </Link>
                    
                    {!userData.phone_verified && (
                      <Link
                        to="/profile"
                        className="block px-4 py-2 text-yellow-600 hover:bg-gray-100"
                      >
                        <i className="fas fa-exclamation-triangle w-5 ml-2"></i>
                        تفعيل الحساب
                      </Link>
                    )}
                    
                    <hr className="my-1" />
                    
                    <button
                      onClick={handleLogout}
                      className="block w-full text-right px-4 py-2 text-red-600 hover:bg-gray-100"
                    >
                      <i className="fas fa-sign-out-alt w-5 ml-2"></i>
                      تسجيل الخروج
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              className="text-white focus:outline-none"
            >
              <i className={`fas ${mobileMenuOpen ? 'fa-times' : 'fa-bars'} text-xl`}></i>
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {mobileMenuOpen && (
          <div className="md:hidden mt-4 bg-white bg-opacity-10 p-4 rounded-lg">
            <div className="flex flex-col gap-3">
              {isLoggedIn ? (
                <>
                  <div className="flex items-center gap-2 mb-2">
                    <OfflineStatus />
                    <PingStatus />
                    <span className="text-white">{userData?.full_name || 'المستخدم'}</span>
                  </div>
                  
                  <Link
                    to="/profile"
                    className="text-white hover:bg-white hover:bg-opacity-10 py-2 px-3 rounded-lg"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <i className="fas fa-user ml-2"></i>
                    الملف الشخصي
                  </Link>
                  
                  <Link
                    to="/create-game"
                    className="text-white hover:bg-white hover:bg-opacity-10 py-2 px-3 rounded-lg"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <i className="fas fa-plus-circle ml-2"></i>
                    لعبة جديدة
                  </Link>
                  
                  <Link
                    to="/games-history"
                    className="text-white hover:bg-white hover:bg-opacity-10 py-2 px-3 rounded-lg"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <i className="fas fa-history ml-2"></i>
                    سجل الألعاب
                  </Link>
                  
                  <button
                    onClick={() => {
                      handleLogout();
                      setMobileMenuOpen(false);
                    }}
                    className="text-right text-white hover:bg-white hover:bg-opacity-10 py-2 px-3 rounded-lg"
                  >
                    <i className="fas fa-sign-out-alt ml-2"></i>
                    تسجيل الخروج
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to="/login"
                    className="text-white hover:bg-white hover:bg-opacity-10 py-2 px-3 rounded-lg"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <i className="fas fa-sign-in-alt ml-2"></i>
                    تسجيل الدخول
                  </Link>
                  
                  <Link
                    to="/login"
                    state={{ activeForm: 'register' }}
                    className="text-white hover:bg-white hover:bg-opacity-10 py-2 px-3 rounded-lg"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <i className="fas fa-user-plus ml-2"></i>
                    حساب جديد
                  </Link>
                </>
              )}
            </div>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Navbar;
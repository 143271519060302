import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../api/apiService';
import { toast } from 'react-toastify';
import Loader from '../components/common/Loader';
import Modal from '../components/common/Modal';
import PingStatus from '../components/common/PingStatus';
import OfflineStatus from '../components/common/OfflineStatus';

const ProfilePage = () => {
  const { isLoggedIn, userData, updateUserData, logout } = useAuth();
  const navigate = useNavigate();
  
  const [loading, setLoading] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [profileData, setProfileData] = useState({
    full_name: '',
    username: '',
    email: '',
    phone: ''
  });
  const [passwordData, setPasswordData] = useState({
    current_password: '',
    new_password: '',
    new_password_confirmation: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  
  // Load user data
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { state: { from: { pathname: '/profile' } } });
      return;
    }
    
    // Set initial profile data
    setProfileData({
      full_name: userData.full_name || '',
      username: userData.username || '',
      email: userData.email || '',
      phone: userData.phone || ''
    });
  }, [isLoggedIn, userData, navigate]);
  
  // Handle edit profile
  const handleEditProfile = async (e) => {
    e.preventDefault();
    
    // Validate form
    const newErrors = {};
    if (!profileData.full_name.trim()) {
      newErrors.full_name = 'الاسم الكامل مطلوب';
    }
    
    if (!profileData.username.trim()) {
      newErrors.username = 'اسم المستخدم مطلوب';
    } else if (!/^[a-zA-Z0-9_]{3,20}$/.test(profileData.username)) {
      newErrors.username = 'اسم المستخدم يجب أن يكون من 3 إلى 20 حرفاً ويحتوي على أحرف وأرقام وشرطة سفلية فقط';
    }
    
    if (!profileData.email.trim()) {
      newErrors.email = 'البريد الإلكتروني مطلوب';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(profileData.email)) {
      newErrors.email = 'البريد الإلكتروني غير صحيح';
    }
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    setLoading(true);
    setErrors({});
    
    try {
      const response = await apiService.request('update_profile', 'POST', profileData);
      
      if (response.data.success) {
        // Update user data in context
        updateUserData(response.data.data);
        setShowEditProfileModal(false);
        toast.success('تم تحديث الملف الشخصي بنجاح');
      } else {
        // Handle error
        if (response.data.errors) {
          setErrors(response.data.errors);
        } else {
          toast.error(response.data.message || 'فشل في تحديث الملف الشخصي');
        }
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      
      if (error.response?.status === 422) {
        // Validation errors
        setErrors(error.response.data.errors || {});
      } else {
        toast.error(error.response?.data?.message || 'حدث خطأ أثناء تحديث الملف الشخصي');
      }
    } finally {
      setLoading(false);
    }
  };
  
  // Handle change password
  const handleChangePassword = async (e) => {
    e.preventDefault();
    
    // Validate form
    const newErrors = {};
    if (!passwordData.current_password) {
      newErrors.current_password = 'كلمة المرور الحالية مطلوبة';
    }
    
    if (!passwordData.new_password) {
      newErrors.new_password = 'كلمة المرور الجديدة مطلوبة';
    } else if (passwordData.new_password.length < 8) {
      newErrors.new_password = 'كلمة المرور يجب أن تكون 8 أحرف على الأقل';
    }
    
    if (!passwordData.new_password_confirmation) {
      newErrors.new_password_confirmation = 'تأكيد كلمة المرور مطلوب';
    } else if (passwordData.new_password !== passwordData.new_password_confirmation) {
      newErrors.new_password_confirmation = 'كلمة المرور غير متطابقة';
    }
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    
    setLoading(true);
    setErrors({});
    
    try {
      const response = await apiService.request('change_password', 'POST', passwordData);
      
      if (response.data.success) {
        setShowChangePasswordModal(false);
        setPasswordData({
          current_password: '',
          new_password: '',
          new_password_confirmation: ''
        });
        toast.success('تم تغيير كلمة المرور بنجاح');
      } else {
        // Handle error
        if (response.data.errors) {
          setErrors(response.data.errors);
        } else {
          toast.error(response.data.message || 'فشل في تغيير كلمة المرور');
        }
      }
    } catch (error) {
      console.error('Error changing password:', error);
      
      if (error.response?.status === 422) {
        // Validation errors
        setErrors(error.response.data.errors || {});
      } else {
        toast.error(error.response?.data?.message || 'حدث خطأ أثناء تغيير كلمة المرور');
      }
    } finally {
      setLoading(false);
    }
  };
  
  // Handle input change for profile edit
  const handleProfileChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };
  
  // Handle input change for password change
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };
  
  // Handle logout
  const handleLogout = async () => {
    await logout();
    navigate('/');
  };
  
  if (!isLoggedIn || !userData) {
    return <Loader />;
  }
  
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <header className="bg-gradient-to-r from-teal-400 to-blue-500 text-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold">
              فكر وإربح
            </Link>
            
            <div className="flex items-center gap-4">
              <button
                onClick={handleLogout}
                className="bg-white text-blue-600 px-4 py-2 rounded-lg hover:bg-blue-50 transition"
              >
                <i className="fas fa-sign-out-alt ml-1"></i>
                تسجيل الخروج
              </button>
            </div>
          </div>
        </div>
      </header>
      
      {/* Main Content */}
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-4xl mx-auto">
            {/* Profile Header */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-6">
              <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
                <div className="w-24 h-24 bg-gradient-to-br from-blue-500 to-teal-400 rounded-full flex items-center justify-center text-white text-4xl font-bold">
                  {userData.full_name ? userData.full_name[0].toUpperCase() : '?'}
                </div>
                
                <div className="flex-grow text-center md:text-right">
                  <h1 className="text-2xl font-bold">{userData.full_name}</h1>
                  <p className="text-gray-600">@{userData.username}</p>
                  <p className="text-gray-600 mt-1">{userData.email}</p>
                  <p className="text-gray-600">{userData.phone}</p>
                  
                  {userData.phone_verified ? (
                    <div className="inline-flex items-center bg-green-100 text-green-800 text-sm px-3 py-1 rounded-full mt-2">
                      <i className="fas fa-check-circle mr-1"></i>
                      رقم مفعّل
                    </div>
                  ) : (
                    <div className="inline-flex items-center bg-yellow-100 text-yellow-800 text-sm px-3 py-1 rounded-full mt-2">
                      <i className="fas fa-exclamation-circle mr-1"></i>
                      غير مفعّل
                    </div>
                  )}
                </div>
                
                <div className="flex flex-col gap-2">
                  <button
                    onClick={() => setShowEditProfileModal(true)}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition flex items-center justify-center gap-1"
                  >
                    <i className="fas fa-edit"></i>
                    تعديل الملف
                  </button>
                  
                  <button
                    onClick={() => setShowChangePasswordModal(true)}
                    className="bg-gray-500 text-white px-4 py-2 rounded-lg hover:bg-gray-600 transition flex items-center justify-center gap-1"
                  >
                    <i className="fas fa-key"></i>
                    تغيير كلمة المرور
                  </button>
                </div>
              </div>
            </div>
            
            {/* Stats Cards */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-6">
              <div className="bg-white rounded-lg shadow-md p-6">
                <div className="text-3xl font-bold text-blue-500 mb-2">{userData.games_remaining}</div>
                <div className="text-gray-600">الألعاب المتبقية</div>
              </div>
              <div className="bg-white rounded-lg shadow-md p-6">
                <div className="text-3xl font-bold text-green-500 mb-2">{userData.games_played}</div>
                <div className="text-gray-600">الألعاب المكتملة</div>
              </div>
              <div className="bg-white rounded-lg shadow-md p-6">
                <div className="text-3xl font-bold text-purple-500 mb-2">{userData.total_points}</div>
                <div className="text-gray-600">مجموع النقاط</div>
              </div>
              <div className="bg-white rounded-lg shadow-md p-6">
                <div className="text-3xl font-bold text-orange-500 mb-2">
                  <span>{userData.win_percentage}</span>%
                </div>
                <div className="text-gray-600">نسبة الفوز</div>
              </div>
            </div>
            
            {/* Connection Status */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
              <PingStatus showDetails={true} />
              <OfflineStatus showDetails={true} />
            </div>
            
            {/* Subscription Info */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-6">
              <h2 className="text-xl font-bold mb-4">معلومات الاشتراك</h2>
              
              <div className="flex flex-col md:flex-row md:items-center md:justify-between">
                <div>
                  <p className="text-gray-600">
                    <span className="font-semibold">الباقة الحالية:</span> {userData.package_name || 'غير مشترك'}
                  </p>
                  {userData.subscription_expires_at && (
                    <p className="text-gray-600 mt-1">
                      <span className="font-semibold">تاريخ انتهاء الاشتراك:</span> {new Date(userData.subscription_expires_at).toLocaleDateString('ar-EG')}
                    </p>
                  )}
                </div>
                
                <Link
                  to="/#packages"
                  className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition mt-4 md:mt-0 text-center"
                >
                  <i className="fas fa-crown ml-1"></i>
                  ترقية الاشتراك
                </Link>
              </div>
            </div>
            
            {/* Recent Games */}
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">الألعاب الأخيرة</h2>
                
                <Link 
                  to="/games-history"
                  className="text-blue-500 hover:text-blue-600"
                >
                  عرض الكل
                </Link>
              </div>
              
              <div className="overflow-x-auto">
                <table className="w-full border-collapse">
                  <thead>
                    <tr className="bg-gray-50">
                      <th className="px-4 py-2 text-right border-b">اسم اللعبة</th>
                      <th className="px-4 py-2 text-right border-b">التاريخ</th>
                      <th className="px-4 py-2 text-right border-b">الفريق الفائز</th>
                      <th className="px-4 py-2 text-right border-b">عدد الأسئلة</th>
                      <th className="px-4 py-2 text-right border-b">الإجراءات</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example rows - would be populated from API */}
                    <tr className="hover:bg-gray-50">
                      <td className="px-4 py-3 border-b">ليلة المعرفة</td>
                      <td className="px-4 py-3 border-b">٢٠٢٣/١٠/١٥</td>
                      <td className="px-4 py-3 border-b">الصقور</td>
                      <td className="px-4 py-3 border-b">٢٤</td>
                      <td className="px-4 py-3 border-b">
                        <Link
                          to="/game-details/1"
                          className="text-blue-500 hover:text-blue-600"
                        >
                          <i className="fas fa-eye"></i>
                        </Link>
                      </td>
                    </tr>
                    <tr className="hover:bg-gray-50">
                      <td className="px-4 py-3 border-b">تحدي المعلومات</td>
                      <td className="px-4 py-3 border-b">٢٠٢٣/١٠/١٠</td>
                      <td className="px-4 py-3 border-b">النسور</td>
                      <td className="px-4 py-3 border-b">١٨</td>
                      <td className="px-4 py-3 border-b">
                        <Link
                          to="/game-details/2"
                          className="text-blue-500 hover:text-blue-600"
                        >
                          <i className="fas fa-eye"></i>
                        </Link>
                      </td>
                    </tr>
                    {/* No games message */}
                    {false && (
                      <tr>
                        <td colSpan="5" className="px-4 py-6 text-center text-gray-500">
                          لا توجد ألعاب سابقة
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm text-gray-400">
            &copy; 2023 فكر وإربح. جميع الحقوق محفوظة.
          </p>
        </div>
      </footer>
      
      {/* Edit Profile Modal */}
      <Modal
        isOpen={showEditProfileModal}
        onClose={() => setShowEditProfileModal(false)}
        title="تعديل الملف الشخصي"
      >
        <form onSubmit={handleEditProfile}>
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                الاسم الكامل
              </label>
              <input 
                type="text" 
                name="full_name"
                value={profileData.full_name}
                onChange={handleProfileChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  errors.full_name ? 'border-red-500' : ''
                }`}
                required
              />
              {errors.full_name && (
                <p className="text-red-500 text-sm mt-1">{errors.full_name}</p>
              )}
            </div>
            
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                اسم المستخدم
              </label>
              <input 
                type="text" 
                name="username"
                value={profileData.username}
                onChange={handleProfileChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  errors.username ? 'border-red-500' : ''
                }`}
                required
              />
              {errors.username && (
                <p className="text-red-500 text-sm mt-1">{errors.username}</p>
              )}
            </div>
            
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                البريد الإلكتروني
              </label>
              <input 
                type="email" 
                name="email"
                value={profileData.email}
                onChange={handleProfileChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  errors.email ? 'border-red-500' : ''
                }`}
                required
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>
            
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                رقم الهاتف
              </label>
              <input 
                type="tel" 
                name="phone"
                value={profileData.phone}
                onChange={handleProfileChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  errors.phone ? 'border-red-500' : ''
                }`}
                disabled
              />
              <p className="text-gray-500 text-xs mt-1">
                لا يمكن تغيير رقم الهاتف
              </p>
            </div>
            
            <div className="flex justify-end gap-2 pt-2">
              <button 
                type="button"
                onClick={() => setShowEditProfileModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition"
              >
                إلغاء
              </button>
              <button 
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
                disabled={loading}
              >
                {loading ? (
                  <span className="flex items-center justify-center gap-2">
                    <i className="fas fa-spinner fa-spin"></i>
                    جاري الحفظ...
                  </span>
                ) : (
                  'حفظ التغييرات'
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal>
      
      {/* Change Password Modal */}
      <Modal
        isOpen={showChangePasswordModal}
        onClose={() => setShowChangePasswordModal(false)}
        title="تغيير كلمة المرور"
      >
        <form onSubmit={handleChangePassword}>
          <div className="space-y-4">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                كلمة المرور الحالية
              </label>
              <div className="relative">
                <input 
                  type={showPassword ? 'text' : 'password'}
                  name="current_password"
                  value={passwordData.current_password}
                  onChange={handlePasswordChange}
                  className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                    errors.current_password ? 'border-red-500' : ''
                  }`}
                  required
                />
                <button
                  type="button" 
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
                >
                  <i className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'}`}></i>
                </button>
              </div>
              {errors.current_password && (
                <p className="text-red-500 text-sm mt-1">{errors.current_password}</p>
              )}
            </div>
            
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                كلمة المرور الجديدة
              </label>
              <input 
                type={showPassword ? 'text' : 'password'}
                name="new_password"
                value={passwordData.new_password}
                onChange={handlePasswordChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  errors.new_password ? 'border-red-500' : ''
                }`}
                required
              />
              {errors.new_password && (
                <p className="text-red-500 text-sm mt-1">{errors.new_password}</p>
              )}
              <p className="text-gray-500 text-xs mt-1">
                يجب أن تكون كلمة المرور 8 أحرف على الأقل
              </p>
            </div>
            
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2">
                تأكيد كلمة المرور الجديدة
              </label>
              <input 
                type={showPassword ? 'text' : 'password'}
                name="new_password_confirmation"
                value={passwordData.new_password_confirmation}
                onChange={handlePasswordChange}
                className={`w-full px-3 py-2 border rounded-lg focus:outline-none focus:border-blue-500 ${
                  errors.new_password_confirmation ? 'border-red-500' : ''
                }`}
                required
              />
              {errors.new_password_confirmation && (
                <p className="text-red-500 text-sm mt-1">{errors.new_password_confirmation}</p>
              )}
            </div>
            
            <div className="flex justify-end gap-2 pt-2">
              <button 
                type="button"
                onClick={() => setShowChangePasswordModal(false)}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition"
              >
                إلغاء
              </button>
              <button 
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition"
                disabled={loading}
              >
                {loading ? (
                  <span className="flex items-center justify-center gap-2">
                    <i className="fas fa-spinner fa-spin"></i>
                    جاري الحفظ...
                  </span>
                ) : (
                  'تغيير كلمة المرور'
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ProfilePage;
import React, { createContext, useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import apiService from '../api/apiService';
import MediaHandler from '../utils/mediaHandler';

// Create context
export const GameContext = createContext();

// Game provider component
export const GameProvider = ({ children }) => {
  // Game state
  const [gameId, setGameId] = useState(null);
  const [gameName, setGameName] = useState('فكر وإربح');
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState({});
  const [teams, setTeams] = useState([
    { id: 0, name: '', score: 0, color: '', helpOptions: [] },
    { id: 1, name: '', score: 0, color: '', helpOptions: [] }
  ]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentTeamIndex, setCurrentTeamIndex] = useState(0);
  const [showingAnswer, setShowingAnswer] = useState(false);
  const [chooseTeam, setChooseTeam] = useState(false);
  const [timer, setTimer] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [activeEffects, setActiveEffects] = useState(new Map());
  const [loading, setLoading] = useState(false);
  
  // Categories state for selection
  const [availableCategories, setAvailableCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [helpOptions, setHelpOptions] = useState([]);
  
  // Initialize game
  const initGame = async (id) => {
    try {
      setLoading(true);
      
      const response = await apiService.getGameData(id);
      
      if (response.data.success) {
        const data = response.data.data;
        
        // Set game data
        setGameId(id);
        setGameName(data.game?.name || 'فكر وإربح');
        
        // Initialize categories
        if (Array.isArray(data.categories)) {
          const formattedCategories = data.categories.map(category => ({
            id: category.id,
            name: category.name,
            image: category.image || 'placeholder-image.png'
          }));
          setCategories(formattedCategories);
        } else {
          throw new Error('Categories data is invalid');
        }
        
        // Initialize questions
        if (data.questions) {
          const formattedQuestions = {};
          for (const category of data.categories) {
            formattedQuestions[category.id] = {
              easy: initializeQuestionsForDifficulty(data.questions[category.id]?.easy, category.id, 'easy'),
              medium: initializeQuestionsForDifficulty(data.questions[category.id]?.medium, category.id, 'medium'),
              hard: initializeQuestionsForDifficulty(data.questions[category.id]?.hard, category.id, 'hard')
            };
          }
          setQuestions(formattedQuestions);
        } else {
          throw new Error('Questions data is missing');
        }
        
        // Initialize teams
        if (Array.isArray(data.teams)) {
          const formattedTeams = data.teams.map(team => ({
            id: team.id,
            name: team.name,
            score: team.score || 0,
            color: team.color,
            helpOptions: team.help_options.map(option => ({
              ...option,
              used: option.used,
              active: true
            }))
          }));
          setTeams(formattedTeams);
        } else {
          throw new Error('Teams data is invalid');
        }
        
        // Preload media
        await MediaHandler.preloadAllMedia(data.categories);
        
        return true;
      } else {
        throw new Error(response.data.message || 'Failed to load game data');
      }
    } catch (error) {
      console.error('Error initializing game:', error);
      toast.error('Failed to load game data');
      throw error;
    } finally {
      setLoading(false);
    }
  };
  
  // Helper function for initializing questions
  const initializeQuestionsForDifficulty = (questions = [], categoryId, difficulty) => {
    return questions.map(q => ({
      ...q,
      categoryId,
      difficulty,
      icon_number: q.icon_number,
      answered: q.is_answered,
      class: getQuestionClass(difficulty)
    }));
  };
  
  // Get question class from difficulty
  const getQuestionClass = (difficulty) => {
    return {
      'easy': 'CLASS_50',
      'medium': 'CLASS_150',
      'hard': 'CLASS_300'
    }[difficulty] || 'CLASS_50';
  };
  
  // Get difficulty from class
  const getDifficultyFromClass = (classType) => {
    const difficulties = {
      'CLASS_50': 'easy',
      'CLASS_150': 'medium',
      'CLASS_300': 'hard'
    };
    return difficulties[classType] || 'easy';
  };
  
  // Get points for question class
  const getPointsForClass = (classType) => {
    const points = {
      'CLASS_50': 50,
      'CLASS_150': 150,
      'CLASS_300': 300
    };
    return points[classType] || 0;
  };
  
  // Check if category has available questions
  const isCategoryAvailable = (categoryId, difficulty) => {
    if (!questions?.[categoryId]) return false;
    
    const difficultyLevel = getDifficultyFromClass(difficulty);
    return questions[categoryId]?.[difficultyLevel]?.some(q => !q.answered) ?? false;
  };
  
  // Get count of remaining questions in category
  const getQuestionCount = (categoryId, difficulty) => {
    if (!questions?.[categoryId]) return 0;
    
    const difficultyLevel = getDifficultyFromClass(difficulty);
    return questions[categoryId]?.[difficultyLevel]?.filter(q => !q.answered).length ?? 0;
  };
  
  // Timer functions
  const startTimer = () => {
    if (isTimerRunning) return;
    
    const startTime = Date.now() - (timer * 1000);
    setIsTimerRunning(true);
    
    const updateTimer = () => {
      if (!isTimerRunning) return;
      
      const elapsed = Math.floor((Date.now() - startTime) / 1000);
      setTimer(elapsed);
      
      if (elapsed >= 120) {
        stopTimer();
        toast.warning('Time is up!');
        return;
      }
      
      requestAnimationFrame(updateTimer);
    };
    
    requestAnimationFrame(updateTimer);
  };
  
  const stopTimer = () => {
    setIsTimerRunning(false);
  };
  
  const resetTimer = () => {
    stopTimer();
    setTimer(0);
  };
  
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };
  
  // Question selection
  const selectQuestion = async (categoryId, difficulty) => {
    if (!isCategoryAvailable(categoryId, difficulty)) {
      toast.error('This question cannot be selected');
      return false;
    }
    
    try {
      const difficultyLevel = getDifficultyFromClass(difficulty);
      const categoryQuestions = questions[categoryId][difficultyLevel];
      const availableQuestion = categoryQuestions.find(q => !q.answered);
      
      if (availableQuestion) {
        await MediaHandler.stopAllMedia();
        
        if (availableQuestion.question_file) {
          await MediaHandler.preloadMedia(availableQuestion.question_file);
        }
        if (availableQuestion.correct_answer_file) {
          await MediaHandler.preloadMedia(availableQuestion.correct_answer_file);
        }
        
        setCurrentQuestion(availableQuestion);
        setShowingAnswer(false);
        setChooseTeam(false);
        resetTimer();
        startTimer();
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error selecting question:', error);
      toast.error('Error loading question');
      return false;
    }
  };
  
  // Answer and point handling
  const showAnswer = async () => {
    await MediaHandler.stopAllMedia();
    stopTimer();
    setShowingAnswer(true);
    setChooseTeam(false);
    
    setTimeout(async () => {
      await MediaHandler.restoreMediaSources();
    }, 100);
  };
  
  const backToQuestion = async () => {
    await MediaHandler.stopAllMedia();
    setShowingAnswer(false);
    startTimer();
    
    setTimeout(async () => {
      await MediaHandler.restoreMediaSources();
    }, 100);
  };
  
  const selectTeam = async () => {
    const blockedEffect = activeEffects.get(`teamBlock_${currentTeamIndex}`);
    if (blockedEffect?.value) {
      toast.error(`${teams[currentTeamIndex].name} is blocked from answering`);
      await finishQuestion(false);
      return;
    }
    
    setChooseTeam(true);
    setShowingAnswer(false);
    stopTimer();
  };
  
  const awardPoints = async (teamIndex) => {
    try {
      const blockedEffect = activeEffects.get(`teamBlock_${teamIndex}`);
      if (blockedEffect?.value) {
        toast.error(`${teams[teamIndex].name} is blocked from answering`);
        return;
      }
      
      const points = await calculatePoints();
      await recordAnswer(teamIndex, points);
      await finishQuestion(true);
      toast.success(`${teams[teamIndex].name} answered correctly and got ${points} points!`);
      
      // Update score
      const newScore = teams[teamIndex].score + points;
      setTeams(prevTeams => {
        const updatedTeams = [...prevTeams];
        updatedTeams[teamIndex] = {
          ...updatedTeams[teamIndex],
          score: newScore
        };
        return updatedTeams;
      });
      
      // Show score animation
      showScoreAnimation(teamIndex, points);
    } catch (error) {
      console.error('Error awarding points:', error);
      toast.error('Failed to record points');
    }
  };
  
  const noOneAnswered = async () => {
    try {
      await recordAnswerNoOne();
      await finishQuestion(true);
    } catch (error) {
      console.error('Error recording answer:', error);
      toast.error('Failed to record answer');
    }
  };
  
  const calculatePoints = async () => {
    let points = getPointsForClass(currentQuestion.class);
    const multiplier = activeEffects.get('pointsMultiplier')?.value || 1;
    points *= multiplier;
    
    if (activeEffects.get('pointsPenalty')?.value) {
      points *= -1;
    }
    
    return points;
  };
  
  const recordAnswer = async (teamIndex, points) => {
    try {
      const response = await apiService.recordAnswer({
        game_id: gameId,
        question_id: currentQuestion.id,
        team_id: teams[teamIndex].id,
        points: points,
        time_taken: timer
      });
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to record answer');
      }
    } catch (error) {
      console.error('Error recording answer:', error);
      throw error;
    }
  };
  
  const recordAnswerNoOne = async () => {
    try {
      const response = await apiService.recordAnswerNoOne({
        game_id: gameId,
        question_id: currentQuestion.id,
        time_taken: timer
      });
      
      if (!response.data.success) {
        throw new Error(response.data.message || 'Failed to record answer');
      }
    } catch (error) {
      console.error('Error recording answer:', error);
      throw error;
    }
  };
  
  const finishQuestion = async (wasAnswered = true) => {
    if (!currentQuestion) return;
    
    try {
      if (wasAnswered) {
        // Mark question as answered
        setQuestions(prevQuestions => {
          const updatedQuestions = { ...prevQuestions };
          const difficultyLevel = getDifficultyFromClass(currentQuestion.class);
          const categoryId = currentQuestion.categoryId;
          
          updatedQuestions[categoryId][difficultyLevel] = updatedQuestions[categoryId][difficultyLevel].map(q => {
            if (q.id === currentQuestion.id) {
              return { ...q, answered: true };
            }
            return q;
          });
          
          return updatedQuestions;
        });
      }
      
      setChooseTeam(false);
      setShowingAnswer(false);
      setCurrentQuestion(null);
      resetTimer();
      await checkEffects();
      await switchTeam();
      await checkGameProgress();
    } catch (error) {
      console.error('Error finishing question:', error);
      toast.error('Error finishing question');
    }
  };
  
  // Team switching
  const switchTeam = () => {
    setCurrentTeamIndex(1 - currentTeamIndex);
    checkTeamEffects();
    return true;
  };
  
  const checkTeamEffects = () => {
    const blockedEffect = activeEffects.get(`teamBlock_${currentTeamIndex}`);
    if (blockedEffect?.value) {
      return false;
    }
    return true;
  };
  
  // Help options
  const useHelpOption = async (optionId) => {
    try {
      const currentTeam = teams[currentTeamIndex];
      const option = currentTeam.helpOptions.find(o => o.id === optionId);
      
      if (!option) {
        toast.error('Help option not found');
        return false;
      }
      
      if (option.used) {
        toast.error('This help option has already been used');
        return false;
      }
      
      if (option.points_cost > currentTeam.score) {
        toast.error('Not enough points to use this help option');
        return false;
      }
      
      const response = await apiService.useHelpOption({
        game_id: gameId,
        team_id: currentTeam.id,
        help_option_id: optionId
      });
      
      if (response.data.success) {
        // Mark option as used locally
        setTeams(prevTeams => {
          const updatedTeams = [...prevTeams];
          updatedTeams[currentTeamIndex].helpOptions = updatedTeams[currentTeamIndex].helpOptions.map(o => {
            if (o.id === optionId) {
              return { ...o, used: true, used_at: new Date().toISOString() };
            }
            return o;
          });
          return updatedTeams;
        });
        
        // Deduct points if cost exists
        if (option.points_cost > 0) {
          await adjustScore(currentTeamIndex, -option.points_cost);
        }
        
        // Execute the help option effect
        await executeHelpOption(option);
        return true;
      }
      
      toast.error(response.data.message || 'Failed to use help option');
      return false;
    } catch (error) {
      console.error('Help option error:', error);
      toast.error(error.response?.data?.message || 'Failed to use help option');
      return false;
    }
  };
  
  // Execute help option based on function name
  const executeHelpOption = async (option) => {
    try {
      const config = typeof option.config === 'string' ? 
        JSON.parse(option.config) : option.config;
      
      switch (option.function_name) {
        case 'stealQuestion':
          if (!currentQuestion) {
            toast.error('No question to steal');
            return;
          }
          const previousTeam = teams[currentTeamIndex].name;
          setCurrentTeamIndex(1 - currentTeamIndex);
          toast.success(`${teams[1-currentTeamIndex].name} stole the question from ${previousTeam}!`);
          break;
          
        case 'blockOtherTeam':
          const otherTeamIndex = 1 - currentTeamIndex;
          const blockDuration = config?.duration || 1;
          
          setActiveEffects(prev => {
            const newEffects = new Map(prev);
            newEffects.set(`teamBlock_${otherTeamIndex}`, {
              value: true,
              duration: blockDuration,
              type: 'block',
              startTime: Date.now()
            });
            return newEffects;
          });
          
          toast.success(`${teams[otherTeamIndex].name} is blocked from answering the next question!`);
          break;
          
        case 'stealPoints':
          const targetTeamIndex = 1 - currentTeamIndex;
          const stealAmount = config?.amount || 100;
          
          if (stealAmount <= 0 || teams[targetTeamIndex].score < stealAmount) {
            toast.error('Cannot steal points');
            return;
          }
          
          await adjustScore(targetTeamIndex, -stealAmount);
          await adjustScore(currentTeamIndex, stealAmount);
          
          toast.success(`Stole ${stealAmount} points from ${teams[targetTeamIndex].name}!`);
          break;
          
        case 'doublePoints':
          const multiplier = config?.multiplier || 2;
          const multiplierDuration = config?.duration || 1;
          
          setActiveEffects(prev => {
            const newEffects = new Map(prev);
            newEffects.set('pointsMultiplier', {
              value: multiplier,
              duration: multiplierDuration,
              type: 'multiply',
              startTime: Date.now()
            });
            return newEffects;
          });
      
          toast.success(`Points will be multiplied by ${multiplier} for the next question!`);
          break;
          
        // Add other help option handlers
        
        default:
          throw new Error(`Unknown help option: ${option.function_name}`);
      }
      
      // Update effects display
      updateEffectsDisplay();
      
    } catch (error) {
      console.error(`Error executing help option ${option.function_name}:`, error);
      toast.error('Error executing help option');
    }
  };
  
  // Effects management
  const checkEffects = async () => {
    const now = Date.now();
    const expiredEffects = [];
    
    activeEffects.forEach((effect, key) => {
      if (effect.duration !== undefined) {
        const elapsedTime = (now - effect.startTime) / 1000;
        if (elapsedTime >= effect.duration) {
          expiredEffects.push(key);
        }
      }
    });
    
    // Remove expired effects
    if (expiredEffects.length > 0) {
      setActiveEffects(prev => {
        const newEffects = new Map(prev);
        expiredEffects.forEach(key => {
          newEffects.delete(key);
        });
        return newEffects;
      });
      updateEffectsDisplay();
    }
  };
  
  const updateEffectsDisplay = () => {
    // This would typically update UI elements showing active effects
    // In React, this is handled by the component rendering based on state
  };
  
  // Score adjustments
  const adjustScore = async (teamIndex, points) => {
    if (!teams[teamIndex]) {
      console.error('Invalid team index');
      return;
    }
    
    try {
      const team = teams[teamIndex];
      const newScore = Math.max(0, team.score + points);
      
      // Don't make API call if score wouldn't change
      if (newScore === team.score) {
        return;
      }
      
      const response = await apiService.updateTeamScore({
        game_id: gameId,
        team_id: team.id,
        score: newScore
      });
      
      if (response.data.success) {
        // Update local state only after successful API call
        setTeams(prevTeams => {
          const updatedTeams = [...prevTeams];
          updatedTeams[teamIndex] = {
            ...updatedTeams[teamIndex],
            score: newScore
          };
          return updatedTeams;
        });
        showScoreAnimation(teamIndex, points);
      } else {
        throw new Error(response.data.message || 'Failed to update score');
      }
    } catch (error) {
      console.error('Error adjusting score:', error);
      toast.error('Failed to update score');
    }
  };
  
  const showScoreAnimation = (teamIndex, points) => {
    // This would be implemented in the component rendering the score
  };
  
  // Game board and navigation
  const returnToBoard = async () => {
    try {
      await MediaHandler.stopAllMedia();
      
      setCurrentQuestion(null);
      setShowingAnswer(false);
      setChooseTeam(false);
      resetTimer();
      
      await checkEffects();
      
      toast.success('Returned to board successfully');
      return true;
    } catch (error) {
      console.error('Error returning to board:', error);
      toast.error('Error returning to board');
      return false;
    }
  };
  
  // Game progress check
  const checkGameProgress = () => {
    let allAnswered = true;
    for (let categoryId in questions) {
      for (let difficulty in questions[categoryId]) {
        if (questions[categoryId][difficulty].some(q => !q.answered)) {
          allAnswered = false;
          break;
        }
      }
      if (!allAnswered) break;
    }
    
    return allAnswered;
  };
  
  // End game
  const endGame = async () => {
    try {
      if (!window.confirm('Are you sure you want to end the game?')) {
        return false;
      }
      
      const response = await apiService.endGame({
        game_id: gameId
      });
      
      if (response.data.success) {
        await MediaHandler.stopAllMedia();
        return response.data.data;
      }
      return false;
    } catch (error) {
      console.error('Error ending game:', error);
      toast.error('Failed to end game');
      return false;
    }
  };
  
  // Submit complaint about question
  const submitComplaint = async (questionId, description) => {
    try {
      const response = await apiService.complaints({
        game_id: gameId,
        question_id: questionId,
        description: description
      });
      
      if (response.data.success) {
        toast.success('Complaint submitted successfully');
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error submitting complaint:', error);
      toast.error('Failed to submit complaint');
      return false;
    }
  };
  
  // Load categories and help options for game creation
  const loadGameCreationData = async () => {
    try {
      setLoading(true);
      
      const [categoriesResponse, helpOptionsResponse] = await Promise.all([
        apiService.getCategories(),
        apiService.getHelpOptions()
      ]);
      
      if (categoriesResponse.data.success) {
        setAvailableCategories(categoriesResponse.data.data.map(category => ({
          ...category,
          image: category.image || 'placeholder-image.png'
        })));
      }
      
      if (helpOptionsResponse.data.success) {
        setHelpOptions(helpOptionsResponse.data.data.map(option => ({
          ...option,
          config: typeof option.config === 'string' ? 
            JSON.parse(option.config) : option.config
        })));
      }
      
      return true;
    } catch (error) {
      console.error('Error loading game creation data:', error);
      toast.error('Failed to load categories and help options');
      return false;
    } finally {
      setLoading(false);
    }
  };
  
  // Create a new game
  const createGame = async (gameData) => {
    try {
      setLoading(true);
      
      const response = await apiService.createGame(gameData);
      
      if (response.data.success) {
        toast.success('Game created successfully');
        return response.data.data.game_id;
      }
      
      return null;
    } catch (error) {
      console.error('Error creating game:', error);
      toast.error(error.response?.data?.message || 'Failed to create game');
      return null;
    } finally {
      setLoading(false);
    }
  };
  
  // Toggle category selection
  const toggleCategory = (categoryId) => {
    setSelectedCategories(prev => {
      const index = prev.indexOf(categoryId);
      if (index === -1) {
        if (prev.length < 6) {
          return [...prev, categoryId];
        } else {
          toast.warning('Cannot select more than 6 categories');
          return prev;
        }
      } else {
        return prev.filter(id => id !== categoryId);
      }
    });
  };
  
  // Context value
  const value = {
    gameId,
    gameName,
    categories,
    questions,
    teams,
    currentQuestion,
    currentTeamIndex,
    showingAnswer,
    chooseTeam,
    timer,
    isTimerRunning,
    activeEffects,
    loading,
    availableCategories,
    selectedCategories,
    helpOptions,
    setGameId,
    initGame,
    selectQuestion,
    showAnswer,
    backToQuestion,
    selectTeam,
    awardPoints,
    noOneAnswered,
    finishQuestion,
    switchTeam,
    useHelpOption,
    adjustScore,
    startTimer,
    stopTimer,
    resetTimer,
    formatTime,
    returnToBoard,
    endGame,
    submitComplaint,
    loadGameCreationData,
    createGame,
    toggleCategory,
    isCategoryAvailable,
    getQuestionCount,
    getPointsForClass
  };
  
  return (
    <GameContext.Provider value={value}>
      {children}
    </GameContext.Provider>
  );
};

// Custom hook to use the game context
export const useGame = () => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error('useGame must be used within a GameProvider');
  }
  return context;
};
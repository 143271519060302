import axios from 'axios';
import { toast } from 'react-toastify';
import offlineStorage, { 
  isOfflineMode, 
  queueAction,
  cacheGameData,
  getCachedGameData,
  cacheCategories,
  getCachedCategories,
  cacheUserData,
  getCachedUserData
} from '../utils/offlineStorage';

const API_URL = '/api_game.php';

// Define which requests can be cached for offline use
const CACHEABLE_ACTIONS = ['get_categories', 'get_game_data', 'get_profile', 'get_help_options'];

// Define which requests can be queued for later when offline
const QUEUEABLE_ACTIONS = [
  'record_answer',
  'record_answer_no_one',
  'update_team_score',
  'end_game',
  'complaints',
  'use_help_option'
];

const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  },
  withCredentials: true // Enable sending cookies with requests
});

// Add a request interceptor to include CSRF token
api.interceptors.request.use(
  (config) => {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
    if (csrfToken) {
      config.headers['X-CSRF-TOKEN'] = csrfToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor to handle common errors
api.interceptors.response.use(
  (response) => {
    // Cache successful responses for offline use
    if (response.config && response.data.success) {
      const action = getActionFromConfig(response.config);
      
      if (CACHEABLE_ACTIONS.includes(action)) {
        cacheResponse(action, response);
      }
    }
    return response;
  },
  (error) => {
    // Don't show error messages for ping requests
    const isPingRequest = error.config?.headers?.['X-Ping'] === 'React';
    
    // Check if we're offline
    if (!navigator.onLine || (error.request && !error.response)) {
      // If this is an action we can queue for later
      const action = getActionFromConfig(error.config);
      
      if (QUEUEABLE_ACTIONS.includes(action) && !isPingRequest) {
        const method = error.config.method.toUpperCase();
        const data = method === 'GET' ? error.config.params : JSON.parse(error.config.data || '{}');
        
        queueAction(action, method, data);
        
        toast.info('تم حفظ الإجراء للمزامنة لاحقًا عندما تكون متصلًا بالإنترنت');
        
        // Return a mock successful response
        return Promise.resolve({
          data: {
            success: true,
            message: 'Action queued for later synchronization',
            data: null,
            offline: true
          }
        });
      }
    }
    
    if (error.response) {
      if (error.response.status === 401) {
        // Redirect to login page on unauthorized (but not for ping)
        if (!isPingRequest) {
          window.location.href = '/';
        }
      } else if (error.response.status === 403 && !isPingRequest) {
        toast.error('لا تملك الصلاحية للقيام بهذا الإجراء');
      } else if (error.response.status === 500 && !isPingRequest) {
        toast.error('خطأ في الخادم. الرجاء المحاولة لاحقًا');
      }
    } else if (error.request && !isPingRequest) {
      toast.error('لا يوجد استجابة من الخادم. تحقق من اتصالك بالإنترنت');
    } else if (!isPingRequest) {
      toast.error(`خطأ: ${error.message}`);
    }
    return Promise.reject(error);
  }
);

// Extract action name from config
const getActionFromConfig = (config) => {
  if (!config || !config.url) return null;
  
  const url = config.url;
  const actionMatch = url.match(/[?&]action=([^&]*)/);
  return actionMatch ? actionMatch[1] : null;
};

// Cache responses based on action type
const cacheResponse = (action, response) => {
  switch (action) {
    case 'get_categories':
      cacheCategories(response.data.data);
      break;
    case 'get_game_data':
      const gameId = getParamFromUrl(response.config.url, 'game_id');
      if (gameId) {
        cacheGameData(gameId, response.data.data);
      }
      break;
    case 'get_profile':
      cacheUserData(response.data.data);
      break;
    default:
      break;
  }
};

// Get parameter from URL
const getParamFromUrl = (url, paramName) => {
  const regex = new RegExp(`[?&]${paramName}=([^&]*)`);
  const match = url.match(regex);
  return match ? match[1] : null;
};

// Check if request should use cached data
const shouldUseCache = (action, config) => {
  return (isOfflineMode() || !navigator.onLine) && CACHEABLE_ACTIONS.includes(action);
};

// Get cached data for an action
const getCachedData = (action, config) => {
  switch (action) {
    case 'get_categories':
      return getCachedCategories();
    case 'get_game_data':
      const gameId = config.params?.game_id || getParamFromUrl(config.url, 'game_id');
      return gameId ? getCachedGameData(gameId) : null;
    case 'get_profile':
      return getCachedUserData();
    default:
      return null;
  }
};

// Create an API request handler with offline support
const createApiRequest = (action, method, configCallback) => {
  return async (args) => {
    const config = configCallback(args);
    
    // Check if we should use cached data
    if (shouldUseCache(action, config)) {
      const cachedData = getCachedData(action, config);
      
      if (cachedData) {
        // Return cached data in the same format as a regular response
        return Promise.resolve({
          data: {
            success: true,
            data: cachedData,
            message: 'Data loaded from cache',
            cached: true
          }
        });
      } else if (!navigator.onLine) {
        // If we're offline and don't have cached data
        return Promise.reject({
          message: 'No cached data available while offline'
        });
      }
    }
    
    // If we're not using cached data, proceed with the actual API request
    return api(config);
  };
};

// API methods
export const apiService = {
  // Auth endpoints
  login: createApiRequest('login', 'POST', (credentials) => ({
    method: 'POST',
    url: '?action=login',
    data: credentials
  })),
  
  register: createApiRequest('register', 'POST', (userData) => ({
    method: 'POST',
    url: '?action=register',
    data: userData
  })),
  
  logout: createApiRequest('logout', 'POST', () => ({
    method: 'POST',
    url: '?action=logout'
  })),
  
  getProfile: createApiRequest('get_profile', 'GET', (isPing = false) => ({
    method: 'GET',
    url: '?action=get_profile',
    headers: isPing ? { 'X-Ping': 'React' } : undefined
  })),
  
  verifyPhone: createApiRequest('verify_phone', 'POST', (data) => ({
    method: 'POST',
    url: '?action=verify_phone',
    data
  })),
  
  resendVerification: createApiRequest('resend_verification', 'POST', (data) => ({
    method: 'POST',
    url: '?action=resend_verification',
    data
  })),
  
  // Ping service - using the API endpoint from api_game.php
  ping: createApiRequest('get_profile', 'GET', () => ({
    method: 'GET',
    url: '?action=get_profile',
    headers: {
      'X-Ping': 'React',
      'X-Requested-With': 'XMLHttpRequest'
    },
    timeout: 10000
  })),

  // Game management
  getCategories: createApiRequest('get_categories', 'GET', () => ({
    method: 'GET',
    url: '?action=get_categories'
  })),
  
  getHelpOptions: createApiRequest('get_help_options', 'GET', () => ({
    method: 'GET',
    url: '?action=get_help_options'
  })),
  
  createGame: createApiRequest('create_new_game', 'POST', (gameData) => ({
    method: 'POST',
    url: '?action=create_new_game',
    data: gameData
  })),
  
  getGameData: createApiRequest('get_game_data', 'GET', (gameId) => ({
    method: 'GET',
    url: `?action=get_game_data&game_id=${gameId}`
  })),
  
  useHelpOption: createApiRequest('use_help_option', 'POST', (data) => ({
    method: 'POST',
    url: '?action=use_help_option',
    data
  })),
  
  getWheelOptions: createApiRequest('get_wheel_options', 'GET', (data) => ({
    method: 'GET',
    url: '?action=get_wheel_options',
    params: data
  })),
  
  updateTeamScore: createApiRequest('update_team_score', 'POST', (data) => ({
    method: 'POST',
    url: '?action=update_team_score',
    data
  })),
  
  getNewQuestion: createApiRequest('get_new_question', 'GET', (data) => ({
    method: 'GET',
    url: '?action=get_new_question',
    params: data
  })),
  
  recordAnswer: createApiRequest('record_answer', 'POST', (data) => ({
    method: 'POST',
    url: '?action=record_answer',
    data
  })),
  
  recordAnswerNoOne: createApiRequest('record_answer_no_one', 'POST', (data) => ({
    method: 'POST',
    url: '?action=record_answer_no_one',
    data
  })),
  
  endGame: createApiRequest('end_game', 'POST', (data) => ({
    method: 'POST',
    url: '?action=end_game',
    data
  })),
  
  complaints: createApiRequest('complaints', 'POST', (data) => ({
    method: 'POST',
    url: '?action=complaints',
    data
  })),

  // Packages
  selectPackage: createApiRequest('select_package', 'POST', (data) => ({
    method: 'POST',
    url: '?action=select_package',
    data
  })),
  
  // Generic request method
  request: (action, method = 'GET', data = null) => {
    const config = {
      method,
      url: `?action=${action}`,
    };

    if (data) {
      if (method === 'GET') {
        config.params = data;
      } else {
        config.data = data;
      }
    }

    // Check if we should use cached data for this request
    if (shouldUseCache(action, config)) {
      const cachedData = getCachedData(action, config);
      
      if (cachedData) {
        return Promise.resolve({
          data: {
            success: true,
            data: cachedData,
            message: 'Data loaded from cache',
            cached: true
          }
        });
      }
    }

    // Check if we should queue the request
    if ((!navigator.onLine || isOfflineMode()) && QUEUEABLE_ACTIONS.includes(action) && method !== 'GET') {
      const actionId = queueAction(action, method, data);
      
      toast.info('تم حفظ الإجراء للمزامنة لاحقًا عندما تكون متصلًا بالإنترنت');
      
      return Promise.resolve({
        data: {
          success: true,
          message: 'Action queued for later synchronization',
          data: null,
          offline: true,
          actionId
        }
      });
    }

    return api(config);
  }
};

export default apiService;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../api/apiService';
import { toast } from 'react-toastify';
import Loader from '../components/common/Loader';

const GamesHistoryPage = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  
  const [games, setGames] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filter, setFilter] = useState('all'); // all, active, completed
  
  // Load games
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { state: { from: { pathname: '/games-history' } } });
      return;
    }
    
    fetchGames();
  }, [isLoggedIn, page, filter, navigate]);
  
  const fetchGames = async () => {
    setLoading(true);
    
    try {
      const response = await apiService.request('get_games_history', 'GET', {
        page,
        filter
      });
      
      if (response.data.success) {
        setGames(response.data.data.games);
        setTotalPages(response.data.data.pagination.total_pages);
      } else {
        toast.error(response.data.message || 'فشل في تحميل الألعاب');
      }
    } catch (error) {
      console.error('Error fetching games:', error);
      toast.error('حدث خطأ أثناء تحميل الألعاب');
    } finally {
      setLoading(false);
    }
  };
  
  // Format date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('ar-EG', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  
  // Get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'completed':
        return 'bg-blue-100 text-blue-800';
      case 'canceled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <header className="bg-gradient-to-r from-teal-400 to-blue-500 text-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold">
              فكر وإربح
            </Link>
            
            <div className="flex items-center gap-4">
              <Link
                to="/profile"
                className="bg-white text-blue-600 px-4 py-2 rounded-lg hover:bg-blue-50 transition"
              >
                <i className="fas fa-user ml-1"></i>
                الملف الشخصي
              </Link>
            </div>
          </div>
        </div>
      </header>
      
      {/* Main Content */}
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-6xl mx-auto">
            <div className="flex justify-between items-center mb-6">
              <h1 className="text-2xl font-bold">سجل الألعاب</h1>
              
              <Link
                to="/create-game"
                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
              >
                <i className="fas fa-plus ml-1"></i>
                لعبة جديدة
              </Link>
            </div>
            
            {/* Filters */}
            <div className="bg-white rounded-lg shadow-md p-4 mb-6">
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => {
                    setFilter('all');
                    setPage(1);
                  }}
                  className={`px-4 py-2 rounded-md transition ${
                    filter === 'all' 
                      ? 'bg-blue-500 text-white' 
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  جميع الألعاب
                </button>
                
                <button
                  onClick={() => {
                    setFilter('active');
                    setPage(1);
                  }}
                  className={`px-4 py-2 rounded-md transition ${
                    filter === 'active' 
                      ? 'bg-green-500 text-white' 
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  الألعاب النشطة
                </button>
                
                <button
                  onClick={() => {
                    setFilter('completed');
                    setPage(1);
                  }}
                  className={`px-4 py-2 rounded-md transition ${
                    filter === 'completed' 
                      ? 'bg-blue-700 text-white' 
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  الألعاب المكتملة
                </button>
              </div>
            </div>
            
            {/* Games List */}
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              {loading ? (
                <div className="p-8 text-center">
                  <div className="loader mx-auto"></div>
                  <p className="mt-4 text-gray-600">جاري تحميل الألعاب...</p>
                </div>
              ) : games.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="w-full border-collapse">
                    <thead>
                      <tr className="bg-gray-50">
                        <th className="px-4 py-2 text-right border-b">اسم اللعبة</th>
                        <th className="px-4 py-2 text-right border-b">التاريخ</th>
                        <th className="px-4 py-2 text-right border-b">الفرق</th>
                        <th className="px-4 py-2 text-right border-b">الحالة</th>
                        <th className="px-4 py-2 text-right border-b">الفريق الفائز</th>
                        <th className="px-4 py-2 text-right border-b">الإجراءات</th>
                      </tr>
                    </thead>
                    <tbody>
                      {games.map(game => (
                        <tr key={game.id} className="hover:bg-gray-50">
                          <td className="px-4 py-3 border-b">{game.name}</td>
                          <td className="px-4 py-3 border-b">{formatDate(game.created_at)}</td>
                          <td className="px-4 py-3 border-b">
                            <div>{game.team1_name}</div>
                            <div>{game.team2_name}</div>
                          </td>
                          <td className="px-4 py-3 border-b">
                            <span className={`inline-block px-3 py-1 rounded-full text-xs ${getStatusColor(game.status)}`}>
                              {game.status === 'active' ? 'نشط' : 
                               game.status === 'completed' ? 'مكتمل' : 
                               game.status === 'canceled' ? 'ملغي' : 'غير معروف'}
                            </span>
                          </td>
                          <td className="px-4 py-3 border-b">
                            {game.status === 'completed' ? (
                              game.winner_team_name || '-'
                            ) : (
                              '-'
                            )}
                          </td>
                          <td className="px-4 py-3 border-b">
                            <div className="flex gap-2">
                              <Link
                                to={`/game-details/${game.id}`}
                                className="text-blue-500 hover:text-blue-600"
                                title="عرض التفاصيل"
                              >
                                <i className="fas fa-eye"></i>
                              </Link>
                              
                              {game.status === 'active' && (
                                <Link
                                  to={`/play/${game.id}`}
                                  className="text-green-500 hover:text-green-600"
                                  title="متابعة اللعب"
                                >
                                  <i className="fas fa-play"></i>
                                </Link>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="p-8 text-center text-gray-500">
                  <i className="fas fa-gamepad text-5xl mb-4 opacity-30"></i>
                  <p>لا توجد ألعاب متاحة</p>
                  <Link
                    to="/create-game"
                    className="mt-4 inline-block bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  >
                    إنشاء لعبة جديدة
                  </Link>
                </div>
              )}
              
              {/* Pagination */}
              {totalPages > 1 && (
                <div className="flex justify-center items-center p-4 border-t">
                  <nav className="flex gap-1">
                    <button
                      onClick={() => setPage(p => Math.max(p - 1, 1))}
                      disabled={page === 1}
                      className="px-3 py-1 rounded border enabled:hover:bg-gray-100 disabled:opacity-50"
                    >
                      <i className="fas fa-chevron-right"></i>
                    </button>
                    
                    {[...Array(totalPages)].map((_, i) => (
                      <button
                        key={i}
                        onClick={() => setPage(i + 1)}
                        className={`px-3 py-1 rounded border ${
                          page === i + 1 
                            ? 'bg-blue-500 text-white' 
                            : 'hover:bg-gray-100'
                        }`}
                      >
                        {i + 1}
                      </button>
                    ))}
                    
                    <button
                      onClick={() => setPage(p => Math.min(p + 1, totalPages))}
                      disabled={page === totalPages}
                      className="px-3 py-1 rounded border enabled:hover:bg-gray-100 disabled:opacity-50"
                    >
                      <i className="fas fa-chevron-left"></i>
                    </button>
                  </nav>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm text-gray-400">
            &copy; 2023 فكر وإربح. جميع الحقوق محفوظة.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default GamesHistoryPage;
import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import apiService from '../api/apiService';
import { toast } from 'react-toastify';
import Loader from '../components/common/Loader';

const GameDetailsPage = () => {
  const { gameId } = useParams();
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  
  const [game, setGame] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');
  
  // Load game details
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login', { state: { from: { pathname: `/game-details/${gameId}` } } });
      return;
    }
    
    fetchGameDetails();
  }, [isLoggedIn, gameId, navigate]);
  
  const fetchGameDetails = async () => {
    setLoading(true);
    
    try {
      const response = await apiService.request('get_game_details', 'GET', {
        game_id: gameId
      });
      
      if (response.data.success) {
        setGame(response.data.data.game);
        setQuestions(response.data.data.questions || []);
      } else {
        toast.error(response.data.message || 'فشل في تحميل تفاصيل اللعبة');
        navigate('/games-history');
      }
    } catch (error) {
      console.error('Error fetching game details:', error);
      toast.error('حدث خطأ أثناء تحميل تفاصيل اللعبة');
      navigate('/games-history');
    } finally {
      setLoading(false);
    }
  };
  
  // Format date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('ar-EG', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };
  
  // Get status color
  const getStatusColor = (status) => {
    switch (status) {
      case 'active':
        return 'bg-green-100 text-green-800';
      case 'completed':
        return 'bg-blue-100 text-blue-800';
      case 'canceled':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  // Get difficulty label
  const getDifficultyLabel = (difficulty) => {
    switch (difficulty) {
      case 'easy':
        return 'سهل';
      case 'medium':
        return 'متوسط';
      case 'hard':
        return 'صعب';
      default:
        return difficulty;
    }
  };
  
  // Get difficulty color
  const getDifficultyColor = (difficulty) => {
    switch (difficulty) {
      case 'easy':
        return 'bg-green-100 text-green-800';
      case 'medium':
        return 'bg-yellow-100 text-yellow-800';
      case 'hard':
        return 'bg-red-100 text-red-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };
  
  if (loading) {
    return <Loader text="جاري تحميل تفاصيل اللعبة..." />;
  }
  
  if (!game) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center">
        <div className="text-center">
          <i className="fas fa-exclamation-circle text-5xl text-red-500 mb-4"></i>
          <h1 className="text-2xl font-bold mb-2">اللعبة غير موجودة</h1>
          <p className="text-gray-600 mb-6">لم يتم العثور على اللعبة المطلوبة</p>
          
          <Link
            to="/games-history"
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
          >
            العودة لسجل الألعاب
          </Link>
        </div>
      </div>
    );
  }
  
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      {/* Header */}
      <header className="bg-gradient-to-r from-teal-400 to-blue-500 text-white shadow-md">
        <div className="container mx-auto px-4 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold">
              فكر وإربح
            </Link>
            
            <div className="flex items-center gap-4">
              <Link
                to="/games-history"
                className="bg-white text-blue-600 px-4 py-2 rounded-lg hover:bg-blue-50 transition"
              >
                <i className="fas fa-list ml-1"></i>
                سجل الألعاب
              </Link>
            </div>
          </div>
        </div>
      </header>
      
      {/* Main Content */}
      <main className="flex-grow">
        <div className="container mx-auto px-4 py-8">
          <div className="max-w-6xl mx-auto">
            {/* Game Header */}
            <div className="bg-white rounded-lg shadow-md p-6 mb-6">
              <div className="flex flex-col md:flex-row justify-between md:items-center gap-4">
                <div>
                  <h1 className="text-2xl font-bold">{game.name}</h1>
                  <p className="text-gray-600 mt-1">{formatDate(game.created_at)}</p>
                  
                  <div className="flex items-center gap-2 mt-2">
                    <span className={`px-3 py-1 rounded-full text-xs ${getStatusColor(game.status)}`}>
                      {game.status === 'active' ? 'نشط' : 
                       game.status === 'completed' ? 'مكتمل' : 
                       game.status === 'canceled' ? 'ملغي' : 'غير معروف'}
                    </span>
                    
                    {game.status === 'completed' && game.winner_team_name && (
                      <span className="bg-yellow-100 text-yellow-800 px-3 py-1 rounded-full text-xs">
                        الفائز: {game.winner_team_name}
                      </span>
                    )}
                  </div>
                </div>
                
                <div className="flex gap-2">
                  {game.status === 'active' && (
                    <Link
                      to={`/play/${game.id}`}
                      className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition"
                    >
                      <i className="fas fa-play ml-1"></i>
                      متابعة اللعب
                    </Link>
                  )}
                  
                  <Link
                    to="/create-game"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                  >
                    <i className="fas fa-copy ml-1"></i>
                    نسخ اللعبة
                  </Link>
                </div>
              </div>
            </div>
            
            {/* Tabs */}
            <div className="bg-white rounded-lg shadow-md overflow-hidden">
              <div className="flex border-b">
                <button
                  onClick={() => setActiveTab('overview')}
                  className={`px-4 py-3 font-medium ${
                    activeTab === 'overview' 
                      ? 'bg-blue-500 text-white' 
                      : 'bg-white text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  نظرة عامة
                </button>
                
                <button
                  onClick={() => setActiveTab('questions')}
                  className={`px-4 py-3 font-medium ${
                    activeTab === 'questions' 
                      ? 'bg-blue-500 text-white' 
                      : 'bg-white text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  الأسئلة
                </button>
                
                <button
                  onClick={() => setActiveTab('teams')}
                  className={`px-4 py-3 font-medium ${
                    activeTab === 'teams' 
                      ? 'bg-blue-500 text-white' 
                      : 'bg-white text-gray-700 hover:bg-gray-100'
                  }`}
                >
                  الفرق
                </button>
              </div>
              
              {/* Tab Content */}
              <div className="p-6">
                {/* Overview Tab */}
                {activeTab === 'overview' && (
                  <div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h3 className="text-lg font-bold mb-3">معلومات اللعبة</h3>
                        <table className="w-full">
                          <tbody>
                            <tr>
                              <td className="py-2 pr-2 font-semibold">تاريخ الإنشاء:</td>
                              <td>{formatDate(game.created_at)}</td>
                            </tr>
                            <tr>
                              <td className="py-2 pr-2 font-semibold">المدة:</td>
                              <td>{game.duration || '-'}</td>
                            </tr>
                            <tr>
                              <td className="py-2 pr-2 font-semibold">عدد الأسئلة:</td>
                              <td>{questions.length}</td>
                            </tr>
                            <tr>
                              <td className="py-2 pr-2 font-semibold">الأسئلة المجابة:</td>
                              <td>{questions.filter(q => q.is_answered).length}</td>
                            </tr>
                            <tr>
                              <td className="py-2 pr-2 font-semibold">نظام المساعدات:</td>
                              <td>
                                {game.help_system === 'fixed' ? 'مساعدات محددة' : 
                                 game.help_system === 'random' ? 'مساعدات عشوائية' : 
                                 game.help_system === 'team_choice' ? 'اختيار الفريق' : 
                                 game.help_system}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      
                      <div className="bg-gray-50 p-4 rounded-lg">
                        <h3 className="text-lg font-bold mb-3">نتائج اللعبة</h3>
                        <div className="space-y-4">
                          <div className="flex items-center justify-between">
                            <div>
                              <span className="font-semibold">{game.team1_name}</span>
                              <div className="text-sm text-gray-600">
                                الأسئلة المجابة: {game.team1_questions_answered || 0}
                              </div>
                            </div>
                            <div className="text-2xl font-bold text-blue-500">
                              {game.team1_score || 0}
                            </div>
                          </div>
                          
                          <div className="flex items-center justify-between">
                            <div>
                              <span className="font-semibold">{game.team2_name}</span>
                              <div className="text-sm text-gray-600">
                                الأسئلة المجابة: {game.team2_questions_answered || 0}
                              </div>
                            </div>
                            <div className="text-2xl font-bold text-green-500">
                              {game.team2_score || 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="bg-gray-50 p-4 rounded-lg mb-6">
                      <h3 className="text-lg font-bold mb-3">التصنيفات</h3>
                      <div className="flex flex-wrap gap-2">
                        {game.categories?.map(category => (
                          <div key={category.id} className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full text-sm">
                            {category.name}
                          </div>
                        ))}
                      </div>
                    </div>
                    
                    {game.status === 'completed' && (
                      <div className="bg-yellow-50 border-r-4 border-yellow-400 p-4 rounded-lg">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <i className="fas fa-trophy text-yellow-500 mr-3 text-xl"></i>
                          </div>
                          <div>
                            <p className="font-bold">الفريق الفائز: {game.winner_team_name}</p>
                            <p className="text-yellow-700">
                              بـ {game.winner_team_score || 0} نقطة
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                
                {/* Questions Tab */}
                {activeTab === 'questions' && (
                  <div>
                    {questions.length > 0 ? (
                      <div className="overflow-x-auto">
                        <table className="w-full border-collapse">
                          <thead>
                            <tr className="bg-gray-50">
                              <th className="px-4 py-2 text-right border-b">#</th>
                              <th className="px-4 py-2 text-right border-b">السؤال</th>
                              <th className="px-4 py-2 text-right border-b">التصنيف</th>
                              <th className="px-4 py-2 text-right border-b">الصعوبة</th>
                              <th className="px-4 py-2 text-right border-b">الإجابة</th>
                              <th className="px-4 py-2 text-right border-b">النقاط</th>
                              <th className="px-4 py-2 text-right border-b">الفريق المجيب</th>
                            </tr>
                          </thead>
                          <tbody>
                            {questions.map((question, index) => (
                              <tr key={question.id} className="hover:bg-gray-50">
                                <td className="px-4 py-3 border-b">{index + 1}</td>
                                <td className="px-4 py-3 border-b">{question.question_text}</td>
                                <td className="px-4 py-3 border-b">{question.category_name}</td>
                                <td className="px-4 py-3 border-b">
                                  <span className={`inline-block px-3 py-1 rounded-full text-xs ${getDifficultyColor(question.difficulty)}`}>
                                    {getDifficultyLabel(question.difficulty)}
                                  </span>
                                </td>
                                <td className="px-4 py-3 border-b">{question.correct_answer_text}</td>
                                <td className="px-4 py-3 border-b">{question.points || '-'}</td>
                                <td className="px-4 py-3 border-b">
                                  {question.is_answered ? (
                                    question.answered_by_team_name || 'لا أحد'
                                  ) : (
                                    <span className="text-gray-400">لم تتم الإجابة</span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div className="text-center py-8 text-gray-500">
                        لا توجد أسئلة متاحة لهذه اللعبة
                      </div>
                    )}
                  </div>
                )}
                
                {/* Teams Tab */}
                {activeTab === 'teams' && (
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* Team 1 */}
                    <div className="bg-blue-50 p-6 rounded-lg">
                      <h3 className="text-xl font-bold mb-4 text-blue-900">{game.team1_name}</h3>
                      
                      <div className="mb-4">
                        <div className="flex justify-between items-center mb-2">
                          <span className="font-semibold">النقاط:</span>
                          <span className="text-xl font-bold text-blue-600">{game.team1_score || 0}</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="font-semibold">الأسئلة المجابة:</span>
                          <span>{game.team1_questions_answered || 0}</span>
                        </div>
                      </div>
                      
                      <h4 className="font-semibold mt-6 mb-2">المساعدات المستخدمة:</h4>
                      {game.team1_help_options?.length > 0 ? (
                        <div className="space-y-2">
                          {game.team1_help_options.map(option => (
                            <div key={option.id} className="flex justify-between items-center bg-white p-2 rounded">
                              <div className="flex items-center">
                                <i className={`${option.icon} text-blue-500 ml-2`}></i>
                                <span>{option.name}</span>
                              </div>
                              {option.used ? (
                                <span className="text-xs bg-gray-100 px-2 py-1 rounded">تم الاستخدام</span>
                              ) : (
                                <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded">متاح</span>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-500 text-sm">لا توجد مساعدات</p>
                      )}
                    </div>
                    
                    {/* Team 2 */}
                    <div className="bg-green-50 p-6 rounded-lg">
                      <h3 className="text-xl font-bold mb-4 text-green-900">{game.team2_name}</h3>
                      
                      <div className="mb-4">
                        <div className="flex justify-between items-center mb-2">
                          <span className="font-semibold">النقاط:</span>
                          <span className="text-xl font-bold text-green-600">{game.team2_score || 0}</span>
                        </div>
                        <div className="flex justify-between items-center">
                          <span className="font-semibold">الأسئلة المجابة:</span>
                          <span>{game.team2_questions_answered || 0}</span>
                        </div>
                      </div>
                      
                      <h4 className="font-semibold mt-6 mb-2">المساعدات المستخدمة:</h4>
                      {game.team2_help_options?.length > 0 ? (
                        <div className="space-y-2">
                          {game.team2_help_options.map(option => (
                            <div key={option.id} className="flex justify-between items-center bg-white p-2 rounded">
                              <div className="flex items-center">
                                <i className={`${option.icon} text-green-500 ml-2`}></i>
                                <span>{option.name}</span>
                              </div>
                              {option.used ? (
                                <span className="text-xs bg-gray-100 px-2 py-1 rounded">تم الاستخدام</span>
                              ) : (
                                <span className="text-xs bg-green-100 text-green-800 px-2 py-1 rounded">متاح</span>
                              )}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <p className="text-gray-500 text-sm">لا توجد مساعدات</p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="bg-gray-800 text-white py-4">
        <div className="container mx-auto px-4 text-center">
          <p className="text-sm text-gray-400">
            &copy; 2023 فكر وإربح. جميع الحقوق محفوظة.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default GameDetailsPage;
import React, { useState, useEffect } from 'react';
import { usePing } from '../../contexts/PingContext';

const PingStatus = ({ showDetails = false }) => {
  const { 
    pingStatus, 
    lastPingTime, 
    consecutiveFailures,
    performPing, 
    togglePingEnabled, 
    isPingEnabled, 
    currentInterval 
  } = usePing();

  const [isUpdating, setIsUpdating] = useState(false);
  const [nextPingIn, setNextPingIn] = useState(null);

  // Format the last ping time to be user-friendly
  const formatLastPingTime = () => {
    if (!lastPingTime) return 'لم يتم بعد';
    
    const lastPing = new Date(lastPingTime);
    const now = new Date();
    const diff = Math.floor((now - lastPing) / 1000); // difference in seconds
    
    if (diff < 60) return `منذ ${diff} ثانية`;
    if (diff < 3600) return `منذ ${Math.floor(diff / 60)} دقيقة`;
    if (diff < 86400) return `منذ ${Math.floor(diff / 3600)} ساعة`;
    return `منذ ${Math.floor(diff / 86400)} يوم`;
  };

  // Get interval in human-readable format
  const formatInterval = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    
    return seconds ? `${minutes} دقيقة و ${seconds} ثانية` : `${minutes} دقيقة`;
  };

  // Calculate time until next ping
  useEffect(() => {
    if (!isPingEnabled || pingStatus === 'stopped' || !lastPingTime) {
      setNextPingIn(null);
      return;
    }

    const timer = setInterval(() => {
      if (!lastPingTime) return;

      const lastPing = new Date(lastPingTime);
      const now = new Date();
      const diff = now - lastPing;
      const interval = pingStatus === 'failed' ? 30000 : currentInterval; // Use retry interval if failed
      const remainingTime = Math.max(0, interval - diff);
      
      setNextPingIn(remainingTime);
    }, 1000);

    return () => clearInterval(timer);
  }, [lastPingTime, pingStatus, isPingEnabled, currentInterval]);

  // Format remaining time
  const formatRemainingTime = () => {
    if (nextPingIn === null) return 'غير متاح';
    
    const totalSeconds = Math.floor(nextPingIn / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    
    if (minutes > 0) {
      return `${minutes} دقيقة و ${seconds} ثانية`;
    }
    return `${seconds} ثانية`;
  };

  // Get status color
  const getStatusColor = () => {
    switch (pingStatus) {
      case 'success':
        return 'bg-green-500';
      case 'failed':
        return 'bg-red-500';
      case 'pinging':
        return 'bg-yellow-500 animate-pulse';
      case 'stopped':
        return 'bg-gray-500';
      default:
        return 'bg-blue-500';
    }
  };

  // Get status text
  const getStatusText = () => {
    switch (pingStatus) {
      case 'success':
        return 'متصل';
      case 'failed':
        return 'فشل الاتصال';
      case 'pinging':
        return 'جاري الاتصال...';
      case 'stopped':
        return 'متوقف';
      case 'idle':
        return 'في الانتظار';
      default:
        return pingStatus;
    }
  };

  // Handle ping button click
  const handlePingClick = async () => {
    setIsUpdating(true);
    await performPing();
    setTimeout(() => setIsUpdating(false), 1000);
  };

  if (!showDetails) {
    // Minimal version - just a status indicator dot
    return (
      <div className="flex items-center">
        <div 
          className={`w-2 h-2 rounded-full ${getStatusColor()}`} 
          title={`حالة الاتصال: ${getStatusText()}`}
        ></div>
      </div>
    );
  }

  // Detailed version
  return (
    <div className="bg-white rounded-lg shadow p-4 mb-6">
      <h3 className="text-xl font-bold mb-3">اتصال بالخادم</h3>
      
      <div className="flex items-center justify-between mb-3">
        <span className="text-gray-700">الحالة:</span>
        <div className="flex items-center">
          <div className={`w-3 h-3 rounded-full ${getStatusColor()} mr-2`}></div>
          <span className="font-medium">{getStatusText()}</span>
        </div>
      </div>
      
      <div className="flex items-center justify-between mb-3">
        <span className="text-gray-700">آخر اتصال:</span>
        <span className="font-medium">{formatLastPingTime()}</span>
      </div>
      
      {nextPingIn !== null && (
        <div className="flex items-center justify-between mb-3">
          <span className="text-gray-700">الاتصال التالي:</span>
          <span className="font-medium">{formatRemainingTime()}</span>
        </div>
      )}
      
      {currentInterval && (
        <div className="flex items-center justify-between mb-3">
          <span className="text-gray-700">فترة التحديث:</span>
          <span className="font-medium">{formatInterval(currentInterval)}</span>
        </div>
      )}

      {consecutiveFailures > 0 && (
        <div className="flex items-center justify-between mb-3">
          <span className="text-gray-700">محاولات فاشلة متتالية:</span>
          <span className="font-medium text-orange-500">{consecutiveFailures}</span>
        </div>
      )}
      
      <div className="flex items-center justify-between mb-4">
        <span className="text-gray-700">تفعيل الإتصال التلقائي:</span>
        <label className="relative inline-flex items-center cursor-pointer">
          <input 
            type="checkbox" 
            checked={isPingEnabled}
            onChange={(e) => togglePingEnabled(e.target.checked)}
            className="sr-only peer" 
          />
          <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
        </label>
      </div>
      
      <button 
        onClick={handlePingClick}
        disabled={isUpdating || pingStatus === 'pinging'}
        className={`w-full font-bold py-2 px-4 rounded transition flex items-center justify-center gap-2 ${
          isUpdating || pingStatus === 'pinging' 
            ? 'bg-gray-400 cursor-not-allowed' 
            : 'bg-blue-500 hover:bg-blue-600 text-white'
        }`}
      >
        {isUpdating || pingStatus === 'pinging' ? (
          <>
            <svg className="animate-spin -mr-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            جاري التحديث...
          </>
        ) : (
          <>
            <i className="fas fa-sync-alt"></i>
            تحديث الإتصال الآن
          </>
        )}
      </button>

      <div className="mt-4 text-xs text-gray-500 text-center">
        الاتصال المستمر يساعد على الحفاظ على جلسة المستخدم نشطة لتفادي تسجيل الخروج التلقائي
      </div>
    </div>
  );
};

export default PingStatus;
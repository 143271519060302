import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div>
            <h3 className="text-xl font-bold mb-4">فكر وإربح</h3>
            <p className="text-gray-300">
              منصة تعليمية ترفيهية تهدف إلى إثراء المعرفة والتحفيز على التعلم من خلال المنافسة والتحدي.
            </p>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">روابط مفيدة</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-300 hover:text-white">
                  الصفحة الرئيسية
                </Link>
              </li>
              <li>
                <Link to="/#packages" className="text-gray-300 hover:text-white">
                  الباقات
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="text-gray-300 hover:text-white">
                  سياسة الخصوصية
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-300 hover:text-white">
                  شروط الاستخدام
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-xl font-bold mb-4">تواصل معنا</h3>
            <ul className="space-y-2">
              <li className="flex items-center gap-2">
                <i className="fas fa-envelope text-gray-300"></i>
                <a href="mailto:info@thinkandwin.com" className="text-gray-300 hover:text-white">
                  info@thinkandwin.com
                </a>
              </li>
              <li className="flex items-center gap-2">
                <i className="fas fa-phone text-gray-300"></i>
                <a href="tel:+96512345678" className="text-gray-300 hover:text-white">
                  +965 1234 5678
                </a>
              </li>
            </ul>
            <div className="mt-4 flex space-x-4 space-x-reverse">
              <a href="#" className="text-gray-300 hover:text-white">
                <i className="fab fa-facebook-f"></i>
              </a>
              <a href="#" className="text-gray-300 hover:text-white">
                <i className="fab fa-twitter"></i>
              </a>
              <a href="#" className="text-gray-300 hover:text-white">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
        <hr className="border-gray-700 my-6" />
        <div className="text-center text-gray-400 text-sm">
          &copy; {new Date().getFullYear()} فكر وإربح. جميع الحقوق محفوظة.
        </div>
      </div>
    </footer>
  );
};

export default Footer;